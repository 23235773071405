import { useEffect, useState } from 'react';
import { IoRemoveCircleOutline } from 'react-icons/io5';
import { AvatarImage } from '../../../../../components/avatar-image';
import Pagination from '../../../../../components/pagination';
import { useParams } from 'react-router';
import { useGetAllOrganizationsQuery } from '../../../../../slices/organizationApiSlice';
import { OrganizationModel } from '../../../../../model/organization.model';
import { OutsideClick } from '../../../../../components/outside-click';
import ModalContent from '../../../../../components/modal-content';
import ModalFooter from '../../../../../components/modal-footer';
import { useRemoveOrganizationFromTeamMutation } from '../../../../../slices/teamsApiSlice';
import { useNavigate } from 'react-router-dom';

const TeamOrganizations = () => {
  const param = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useGetAllOrganizationsQuery({ team: param.id });
  const [removeOrg, mutationState] = useRemoveOrganizationFromTeamMutation();
  function handleRemove(id: number, name: string, teamId: number) {
    setSelectedOgma({ id, name, teamId });
  }
  const {
    isSuccess: removeSuccess,
    isError: removeError,
    isLoading: removeLoading,
  } = mutationState;

  const [selectedOgma, setSelectedOgma] = useState<
    { id: number; name: string; teamId: number } | undefined
  >();
  useEffect(() => {
    if (!removeSuccess) return;
    setSelectedOgma(undefined);
  }, [removeSuccess]);
  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='flex flex-col flex-1 justify-between'>
      {selectedOgma ? (
        <RemoveOrgDialog
          {...selectedOgma}
          {...mutationState}
          removeOrg={removeOrg}
          cancel={() => setSelectedOgma(undefined)}
        />
      ) : (
        <></>
      )}
      <div className='flex flex-col gap-2'>
        {removeError && <p className='text-red-700'>Something went wrong! Please try again!</p>}
        {removeSuccess && (
          <p className='text-green-700'>Ogma was removed successfully from this organization!</p>
        )}
        <div className='flex flex-wrap gap-10'>
          {data.data.map((d: any) => {
            const model = new OrganizationModel(d);
            return (
              <div
                key={model.id}
                className='relative flex flex-col p-10 rounded-lg shadow-lg items-center gap-10 w-[200px] overflow-hidden cursor-pointer'
                onClick={() => navigate(`/organizations/view/${model.id}`)}
              >
                <IoRemoveCircleOutline
                  onClick={() => handleRemove(Number(model.id), model.name, Number(param.id))}
                  className='absolute top-1 right-1 hover:text-red-700 cursor-pointer'
                />

                <AvatarImage size='xxl' source={model?.image ?? ''} />

                <h2>{model.name}</h2>
              </div>
            );
          })}
        </div>
      </div>
      <Pagination length={data?.data?.length} {...data?.meta} />
    </div>
  );
};

const RemoveOrgDialog = ({
  id,
  name,
  teamId,
  cancel,
  isLoading,
  isSuccess,
  removeOrg,
}: {
  id: number;
  name: string;
  teamId: number;
  cancel: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  removeOrg: Function;
}) => {
  // if (isSuccess)  cancel();
  return (
    <>
      <OutsideClick />
      <ModalContent extraClass='h-fit' hideFooter name='Remove Organization?'>
        <>
          <div className='flex flex-col p-10 text-center items-center'>
            <p>Are you sure you want to remove {name}?</p>
          </div>
          <ModalFooter
            isLoading={isLoading}
            onCancel={cancel}
            onSubmit={() => removeOrg({ teamId, orgId: id })}
          />
        </>
      </ModalContent>
    </>
  );
};

export default TeamOrganizations;
