import { AllChatMessages } from './all-chat-messages';
import { useState } from 'react';
import { AiOutlineSend } from 'react-icons/ai';
import { SlArrowRight } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { selectMatchChatDisputes, selectMatchData } from '../../../slices/matchSlice';
import { TeamChatMessages } from './team-chat-messages';
import { UserRoles } from '../../../utils/constants';
import { Link, Outlet } from 'react-router-dom';
import { FaFlag } from 'react-icons/fa';
import { LuAlertTriangle } from 'react-icons/lu';

export function ChatMatch({
  setShowChat,
  sendChat,
  tempChat,
  setTempChat,
}: {
  setShowChat: Function;
  sendChat: Function;
  tempChat: any;
  setTempChat: Function;
}) {
  const user = useSelector(selectUser);
  const matchData = useSelector(selectMatchData);
  const matchChatDisuputes = useSelector(selectMatchChatDisputes);
  const isPast = matchData?.chat_end_date ? new Date(matchData.chat_end_date) < new Date() : false;
  const [chatPage, setChatPage] = useState(0);
  const handleSubmit = () => {
    if (!tempChat.length) return;
    if (chatPage) {
      sendChat(tempChat, chatPage);
    } else {
      // this is for all
      sendChat(tempChat);
    }
    // ...s,
    // ]);
    setTempChat('');
  };
  return (
    <>
      <div className='flex flex-col border-2 m-4 h-[60vh] w-full max-w-full bg-white'>
        <div className={`flex justify-between p-2 border-b-2 gap-2`}>
          <div className='w-1/3 flex'>
            {matchChatDisuputes?.length > 0 &&
              (user.roles !== UserRoles.Admin ? (
                <div
                  attr-count={matchChatDisuputes?.length}
                  className='p-1 px-1 rounded-3xl border-2 border-fen-red text-fen-red notification-show before:!-top-2 before:!-right-2 before:!border-fen-red before:!bg-white before:!text-fen-red'
                >
                  <span className='flex items-center gap-2 text-fen-red'>
                    <LuAlertTriangle />
                    Admin Requested
                  </span>
                </div>
              ) : (
                <Link
                  to='./chat-disputes'
                  attr-count={matchChatDisuputes?.length}
                  className='cursor-pointer p-1 px-1 rounded-3xl border-2 border-fen-red text-fen-red notification-show before:!-top-2 before:!-right-2 before:!border-fen-red before:!bg-white before:!text-fen-red'
                >
                  <span className='flex items-center gap-2 text-fen-red'>
                    <LuAlertTriangle />
                    Admin Requested
                  </span>
                </Link>
              ))}
          </div>
          <h2 className='font-bold'>Match Chat</h2>
          <div className='w-1/3 flex justify-end'>
            <Link to={`./dispute-chat/`} className='notification-show before:!-top-2'>
              <button className='cursor-pointer flex items-center justify-center p-1 px-1 bg-fen-red text-white rounded-3xl border-2 min-w-[50px] md:min-w-[150px]'>
                <FaFlag className='mr-1' />
                Flag admin
              </button>
            </Link>
          </div>
        </div>
        <div className='flex p-2 items-center justify-center w-full gap-2'>
          {new Date().toDateString()}
          {isPast && <span className='text-red-600'>Chat is closed!</span>}
          {matchData?.chat_end_date && !isPast && (
            <span className='text-red-600'>
              Chat will close at {new Date(matchData?.chat_end_date).toDateString()}
            </span>
          )}
        </div>
        {chatPage === 0 ? <AllChatMessages /> : <TeamChatMessages id={chatPage} />}
        {!isPast && matchData?.chat_status === 0 && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className='flex p-2  '>
              <div className='flex border-1 w-full items-center outline-2 p-2'>
                <input
                  onChange={(e) => setTempChat(e.target.value)}
                  type='text'
                  placeholder='Start writing...'
                  name=''
                  id=''
                  className='flex-1 active:outline-none focus:outline-none'
                  value={tempChat}
                />
                <AiOutlineSend onClick={handleSubmit} />
              </div>
            </div>
          </form>
        )}
      </div>
      <Outlet />
    </>
  );
}

function ChatTab({
  setChatPage,
  chatPage,
  id,
  name,
}: {
  setChatPage: React.Dispatch<React.SetStateAction<number>>;
  chatPage: number;
  id: number;
  name: string;
}) {
  return (
    <div
      onClick={() => setChatPage(id)}
      className={`cursor-pointer flex items-center justify-center p-1 px-1 rounded-3xl border-2 min-w-[50px] md:min-w-[150px] ${
        chatPage === id ? 'border-black' : ''
      }`}
    >
      {name}
    </div>
  );
}
