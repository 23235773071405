import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NameAndAvatar } from '../../../../../../components/name-and-avatar';
import TableContainer from '../../../../../../components/table-container/table-container';
import { TableHead } from '../../../../../../components/table-head';
import TableTd from '../../../../../../components/table-td/table-td';
import { useGetAllTeamBySchoolIdQuery } from '../../../../../../slices/teamsApiSlice';
import { dateAndTimeFormatter } from '../../../../../../utils/dateFormatter';

const SchoolTeams = () => {
  const params = useParams();
  // @ts-ignore
  const { isLoading, error, data: teamData } = useGetAllTeamBySchoolIdQuery(params.id);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(teamData);
  }, []);
  if (isLoading) {
    return <p>Loading ...</p>;
  }
  function dateAndTeamFormatter(arg0: any) {
    throw new Error('Function not implemented.');
  }

  return (
    <>
      <div className='flex flex-col gap-2'>
        <div className='listViewContainer'>
          <TableContainer>
            <TableHead tableList={['Name', 'Created', 'Num of Players', 'Game', '', '']} />

            <tbody className='bg-white divide-y divide-gray-200'>
              {teamData &&
                teamData.map((o: any) => (
                  <tr key={o.id} onClick={() => navigate(`/teams/view/${o.id}`)}>
                    <TableTd>
                      <NameAndAvatar image={o.School?.image} name={o.teamName ? o.teamName : ''} />
                    </TableTd>
                    <TableTd>{dateAndTimeFormatter(o.created).split(';')[0]}</TableTd>
                    <TableTd>{o.number_of_players}</TableTd>
                    <TableTd>{o.Game.name}</TableTd>
                  </tr>
                ))}
            </tbody>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default SchoolTeams;
