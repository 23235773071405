import { Formik, FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../components/outside-click';
import { useGetSchoolByIdQuery, useUpdateSchoolMutation } from '../../../slices/schoolsApiSlice';
import { useGetAllOrganizationsQuery } from '../../../slices/organizationApiSlice';
import { SchoolModel } from '../../../model/school/school-model';
import { UpdateSchoolYupSchema } from './yup-schema/update-school-yup-schema';
import { number } from 'yup';
import ModalContent from '../../../components/modal-content';
import { CustomError } from '../../../utils/custom-types';
import { allStateCommunities, allStates } from '../../../utils/constants';

const UpdateSchoolModal = () => {
  const { setShowModalSchools } = useStateContext();
  const param = useParams();
  const { error, isSuccess, data, isError, isLoading } = useGetSchoolByIdQuery(param.id);
  const [schema, setSchema] = useState(UpdateSchoolYupSchema);
  const { isLoading: orgIsLoading } = useGetAllOrganizationsQuery('');

  const [updateSchool, { isLoading: updateIsLoading, isSuccess: updateIsSuccess }] =
    useUpdateSchoolMutation();

  const onSubmit = (values: FormikValues) => {
    const data = values;
    data.zipCode = Number(data.zipCode);
    // data.status = Number(data.status);
    delete data.status_name;
    delete data.org_name;
    delete data.organization_id;
    delete data.user_count;
    delete data.schoolState;
    data.coach_enabled = values.coach_enabled ? 1 : 0;
    if (data.organization !== undefined) {
      data.organization_id = data.organization ? +data.organization : null;
    }
    delete data.organization;
    updateSchool({ id: param.id, body: data })
      .unwrap()
      .then((res: any) => console.log(res))
      .catch((error: any) => console.log(error));
  };

  const [enableCoach, setEnableCoach] = useState<Boolean>();
  const formInputs = [
    {
      mainTitle: 'School Information',
      name: 'name',
      label: 'School Name',
      type: 'name',
      placeholder: 'Enter school name',
      isTrue: true,
    },
    {
      mainTitle: 'School Information',
      name: 'streetAddress',
      label: 'Street Address',
      type: 'streetAddress',
      placeholder: 'Enter street address',
    },
    {
      mainTitle: 'School Information',
      name: 'city',
      label: 'City',
      type: 'city',
      placeholder: 'Enter city',
    },
    {
      mainTitle: 'School Information',
      name: 'state',
      label: 'State',
      type: 'text',
      placeholder: 'Enter state',
      isSelect: true,
      options: allStates.concat(allStateCommunities) ?? [],
    },
    {
      mainTitle: 'School Information',
      name: 'zipCode',
      label: 'Zip Code',
      type: 'number',
      placeholder: 'Enter zip code',
    },

    {
      mainTitle: 'School Information',
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'phoneNumber',
      placeholder: 'Enter phone number',
    },

    {
      mainTitle: 'School Information',
      name: 'tags',
      label: 'Tags',
      type: 'tags',
      placeholder: 'Enter tags',
      isTrue: true,
    },
    {
      mainTitle: 'School Information',
      type: 'number',
      min: 1,
      name: 'price',
      label: 'Price of school',
      placeholder: 'Enter price of school',
    },
    {
      mainTitle: 'School Information',
      type: 'number',
      min: 1,
      name: 'price_per_student',
      label: 'Price per student',
      placeholder: 'Enter price per student',
    },
    // {
    //   mainTitle: 'School Information',
    //   type: 'checkbox',
    //   name: 'coach_enabled',
    //   label: 'Enable coaching hours for school?',
    //   placeholder: 'Enter the price per coaching hours',
    //   isTrue: true,
    // },
    // {
    //   mainTitle: 'School Information',
    //   type: 'number',
    //   min: 1,
    //   name: 'coach_hours',
    //   label: 'Coaching hours',
    //   placeholder: 'Amount of coaching hours for this school',
    //   isDisabled: !enableCoach,
    // },
    // {
    //   mainTitle: 'School Information',
    //   type: 'number',
    //   min: 1,
    //   name: 'coach_price',
    //   label: 'Coaching hours fee',
    //   placeholder: 'Enter the price per coaching hours',
    //   isDisabled: !enableCoach,
    // },
    // {
    //   mainTitle: 'School Information',
    //   name: 'status',
    //   label: 'Status',
    //   type: 'status',
    //   placeholder: 'status',
    //   isSelect: true,
    //   options: [
    //     { id: 0, name: 'Disabled' },
    //     { id: 1, name: 'Active' },
    //   ],
    // },
  ];
  useEffect(() => {
    if (enableCoach) {
      setSchema(
        schema.shape({
          coach_price: number().required('Required!'),
          coach_hours: number().required('Required!'),
        }),
      );
    } else {
      setSchema(UpdateSchoolYupSchema);
    }
  }, [enableCoach]);

  if (updateIsSuccess) return <Navigate to='../' />;
  if (isError || (isSuccess && !data))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;

  if (isLoading || orgIsLoading) return <p>Loading...</p>;
  return (
    <>
      <OutsideClick />
      <Formik
        initialValues={{
          ...new SchoolModel(data),
          coach_hours: new SchoolModel(data).coach_hours / 3600,
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ values, errors }) => {
          if (values.coach_enabled) {
            setEnableCoach(true);
          } else {
            setEnableCoach(false);
          }

          return (
            <ModalContent
              errors={Object.keys(errors).length}
              formInputs={formInputs}
              isLoading={isLoading}
              name={`Edit School - ${data.name}`}
            />
          );
        }}
      </Formik>
    </>
  );
};

export default UpdateSchoolModal;
