import * as yup from 'yup';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { OutsideClick } from '../../../../../../components/outside-click';
import {
  useCreateSeasonMutation,
  useGetNewSRequiredDataQuery,
} from '../../../../../../slices/seasonApiSlice';
import { NewSeasonVSchema } from './schema/new-season-v-schema';
import ModalContent from '../../../../../../components/modal-content';
import { addCurrentTimeToDate } from '../../../../../../utils/dateFormatter';
import { allStateCommunities, allStates } from '../../../../../../utils/constants';

function NewSeason() {
  const [avatarImage, setAvatarImage] = useState<any>();
  const [chosenTeam, setChosenTeam] = useState<object[]>([]);
  const [chosenSubDivision, setChosenSubDivision] = useState<object[]>([]);
  const [chosenGame, setChosenGame] = useState<number>();
  const [chosenState, setChosenState] = useState<string[]>([]);
  const [chosenCategoryDivision, setChosenCategoryDivision] = useState<number>();
  const [chosenSeasonType, setChosenSeasonType] = useState<any>();

  const [
    createSeason,
    { data: createData, isLoading: createIsLoading, isSuccess: createIsSuccess },
  ] = useCreateSeasonMutation();

  const {
    data: reqData,
    isLoading: reqIsLoading,
    isSuccess: reqIsSuccess,
    isError: reqIsError,
  } = useGetNewSRequiredDataQuery('');

  const setStateFieldValue = (state: string) => {
    if (chosenState.filter((s) => s === state).length > 0) {
      const newList = chosenState.filter((s) => s !== state);
      if (newList[0] === '') newList.shift();
      setChosenState(newList);
      return newList.join(', ');
    } else {
      setChosenState([...chosenState, state]);
      return [...chosenState, state].join(', ');
    }
  };

  const formInputs = [
    {
      mainTitle: 'Season Information',
      name: 'name',
      label: 'Season Name',
      type: 'text',
      placeholder: 'Enter season name',
      isTrue: true,
    },
    {
      mainTitle: 'Season Information',
      name: 'type_id',
      label: 'Season Type',
      type: 'text',
      placeholder: 'Select the type of season',
      isTrue: true,
      isSelect: true,
      options: reqData?.seasonTypes ? reqData.seasonTypes : [],
    },
    {
      mainTitle: 'Season Information',
      name: 'orderRank',
      label: 'Rank',
      type: 'text',
      placehholder: 'Select the rank type of season',
      isTrue: true,
      isSelect: true,
      options: chosenSeasonType?.leaderboard
        ? [
            { id: 0, name: 'DESC' },
            { id: 1, name: 'ASC' },
          ]
        : [],
      isDisabled: !chosenSeasonType?.leaderboard,
      hidden: !chosenSeasonType?.leaderboard,
    },
    {
      mainTitle: 'Season Information',
      name: 'game_id',
      label: 'Game',
      type: 'text',
      placeholder: 'Game',
      isTrue: true,
      isSelect: true,
      options: reqData?.games ? reqData.games : [],
    },
    {
      mainTitle: 'Season Information',
      name: 'format_id',
      label: 'Match Format',
      type: 'text',
      placeholder: 'Select a match format',
      isTrue: true,
      isSelect: true,
      hidden: chosenSeasonType?.leaderboard,
      options:
        chosenGame && reqData.games.filter((g: any) => g.id === chosenGame)[0].MatchFormat
          ? reqData.games.filter((g: any) => g.id === chosenGame)[0].MatchFormat
          : [],
    },
    {
      mainTitle: 'Season Information',
      name: 'state',
      label: 'State',
      type: 'multiSelect',
      placeholder: 'Enter state',
      isMultiSelect: true,
      options: allStates.concat(allStateCommunities) ?? [],
      setFieldValue: setStateFieldValue,
      selectedValues: chosenState.join(', '),
      multiple: true,
    },
    {
      mainTitle: 'Season Information',
      name: 'teams',
      label: 'Teams',
      type: 'text',
      placeholder: 'Enter teams',
      isTrue: true,
      search: 'team',
      forceState: true,
      setChosenTeam,
      chosenGame,
      chosenState,
      chosenTeam,
      // addTeamsToSeason: true
    },
    {
      mainTitle: 'Season Information',
      name: 'start_date',
      label: 'Start Date',
      type: 'date',
      placeholder: 'Please select start date',
      isTrue: true,
    },
    {
      mainTitle: 'Season Information',
      name: 'end_date',
      label: 'End Date',
      type: 'date',
      placeholder: 'Please select end date',
      isTrue: true,
    },
    {
      mainTitle: 'Season Information',
      name: 'division_id',
      label: 'Category Division',
      type: 'text',
      placeholder: 'Select season category',
      isTrue: true,
      isSelect: true,
      options: reqData?.divisions ? reqData.divisions : [],
    },
    {
      mainTitle: 'Season Information',
      name: 'subdivisions',
      label: 'Subdivisions Name',
      type: 'text',
      placeholder: 'Enter subdivision name',
      isTrue: true,
      search: 'subdivision',
      divisionId: chosenCategoryDivision && chosenCategoryDivision,
      setChosenSubDivision,
      chosenSubDivision,
    },
    {
      mainTitle: 'Season Information',
      name: 'description',
      label: 'Information',
      type: 'textarea',
      placeholder: 'Enter information',
      isTrue: true,
    },
    {
      mainTitle: 'Season Information',
      name: 'image',
      label: 'Background Image',
      type: 'image',
      placeholder: 'Please select an image',
      isTrue: true,
    },
  ];

  useEffect(() => {
    setChosenTeam([]);
  }, [chosenGame, chosenState]);

  function onSubmit(values: any) {
    const data = { ...values };
    data.image = avatarImage;
    data.type_id = +data.type_id;
    data.orderRank = !data.orderRank ? '0' : +data.orderRank;
    data.game_id = +data.game_id;
    data.state = data.state = values.state;
    data.division_id = +data.division_id;
    data.format_id = +data.format_id;
    data.start_date = addCurrentTimeToDate(data.start_date);
    data.end_date = addCurrentTimeToDate(data.end_date);
    data.teams = [];
    chosenTeam.forEach((t: any) => data.teams.push({ id: t.id }));

    data.subdivisions = [];
    chosenSubDivision.forEach(
      (sd: any) => sd.division_id === data.division_id && data.subdivisions.push({ id: sd.id }),
    );

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: any[]) => {
      if (value)
        key === 'teams' || key === 'subdivisions'
          ? formData.append(key, JSON.stringify(value))
          : formData.append(key, value);
    });

    createSeason(formData);
  }

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  initialValues['orderRank'] = '0';

  const newSeasonSchema = NewSeasonVSchema(chosenSeasonType?.leaderboard);
  if (reqIsLoading) return <p>Loading...</p>;
  if (createIsSuccess) return <Navigate to='../' />;

  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={newSeasonSchema.shape({
          image: yup
            .mixed()
            .nullable()
            .test(
              'test2',
              'Invalid image format. Only JPG, PNG, and JPEG formats are allowed.',
              () => {
                const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
                if (avatarImage && !acceptedFormats.includes(avatarImage?.type ?? '')) {
                  return false;
                }
                return true;
              },
            ),
        })}
        initialValues={initialValues}
        onSubmit={onSubmit}
        // className="overflow-scroll"
      >
        {({ values, errors }) => {
          // FIXME: React Hook "useEffect" cannot be called inside a callback. React Hooks must be called in a React function component or a custom React Hook function.
          useEffect(() => {
            if (!values || !reqData) return;
            setChosenGame(+values.game_id);
            setChosenState(chosenState);
            setChosenCategoryDivision(+values.division_id);
            setChosenSeasonType(
              reqData?.seasonTypes?.find((type: any) => type.id === +values.type_id),
            );
          }, [values, reqData]);
          return (
            <>
              <ModalContent
                errors={Object.keys(errors).length}
                setAvatarImage={setAvatarImage}
                formInputs={formInputs.filter((formInput) => !formInput.hidden)}
                // formInputs={formInputs}
                isLoading={createIsLoading}
                name='New Season'
              />
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default NewSeason;
