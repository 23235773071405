import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import TableContainer from '../../../components/table-container/table-container';
import { TableHead } from '../../../components/table-head';
import TableTd from '../../../components/table-td/table-td';
import { Separator } from '../../../components/ui/separator/Separator';
import { Search } from '../../../components/search/search';
import { useGetAllTeamsQuery, useTeamsExportListMutation } from '../../../slices/teamsApiSlice';
import DropdownItem from '../DropDown/DropDownItem';
import { TeamModel } from '../../../model/team.model';
import CanIView from '../../../components/can-i-view/can-i-view';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { SponsorPlacements, UserRoles } from '../../../utils/constants';
import { HeaderButton } from '../../../components/header-button';
import { SponsorRow } from '../../../components/sponsor-row/sponsor-row';
import Pagination from '../../../components/pagination';
import { NameAndAvatar } from '../../../components/name-and-avatar';
import { LoadingSceletonUsers } from '../../../routes/tournaments/routes/all/loading-sceleton-users';
import { TbProgressAlert, TbProgressCheck } from 'react-icons/tb';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../components/ui/tooltip/Tooltip';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu/DropdownMenu';
import { Button } from '../../../components/ui/button/Button';

const Teams = () => {
  const [downloadList, { isLoading: isDownloading }] = useTeamsExportListMutation();
  const [openListView, setOpenListView] = useState(false);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [teamStatusFilterMode, setTeamStatusFilterMode] = useState('All Teams');

  const [showMy, setShowMy] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const initialOpenListState = {
    id: null,
    x: null,
    y: null,
  };
  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);

  function handleClick(prop: { id: number; x: number; y: number }) {
    setOpenListView(false);
    if (openList.id == prop.id) {
      setOpenList(initialOpenListState);
    } else {
      setOpenList(prop);
    }
  }

  const filterList = [
    { title: 'name', property: 'teamName' },
    { title: 'School', property: 'School' },
  ];

  const [searchQuery, setSearchQuery] = useState(filterList[0].title);
  const [search, setSearch] = useState({ by: 'teamName', keyword: '' });

  const {
    data: teamData,
    isLoading,
    error,
  } = useGetAllTeamsQuery({
    param: Object.fromEntries(searchParams.entries()),
    query: { [searchQuery]: searchParams.get(searchQuery) ?? '' },
    page: searchParams.get('page') ? searchParams.get('page') : 1,
    teamStatusFilterMode,
    showMy,
  });

  console.log('teams data....', teamData);

  function handleListView() {
    setOpenList(initialOpenListState);
    setOpenListView(!openListView);
  }

  const configureListView = [
    {
      name: 'Name',
      active: true,
      property: 'name',
    },
    ...(user.roles === UserRoles.Admin
      ? [
          {
            name: 'Created',
            active: true,
            property: 'f_createdAt',
          },
        ]
      : []),
    {
      name: 'School',
      active: true,
      property: 'school',
    },
    {
      name: 'Game',
      active: true,
      property: 'game',
    },
    {
      name: 'Total Players',
      active: true,
      property: 'numberOfStudents',
    },
    {
      name: 'Roster Status',
      active: true,
      property: 'sizeStatus',
    },
    {
      name: 'Seasons',
      active: true,
      property: 'season',
    },
  ];
  const [listView, setListView] = useState(configureListView);
  const headerButtons = [
    {
      type: 'export',
      canIview: 'tournament.export',
      view: true,
      func: () => {
        const query: { [key: string]: string | undefined } = {};
        filterList.forEach((l) => (query[l.property] = searchParams.get(l.property) ?? undefined));
        console.log(query);
        downloadList({ page: searchParams.get('page') ?? '1', params: query });
      },
    },
    {
      type: 'configureListView',
      // view: openListView,
      view: true,
      listView,
      setListView,
    },
    {
      type: 'newTeam',
      canIview: 'team.newTeam',
    },
  ];

  if (isLoading) return <LoadingSceletonUsers />;
  return (
    <>
      <div className='listViewContainer'>
        <SponsorRow name={SponsorPlacements.Teams} />
      </div>
      <div className='listViewContainer border fullPage '>
        <div className='flex w-full justify-between'>
          <div className='flex items-center justify-between'>
            <div className='space-y-1'>
              <h2 className='text-2xl font-semibold tracking-tight'>{`${
                showMy ? 'My Teams' : 'All Teams'
              }`}</h2>
            </div>
          </div>
          <div className='flex gap-2 justify-center'>
            <div className='flex gap-5 flex-wrap md:justify-end justify-center content-center items-center'>
              {headerButtons.map(({ canIview, ...others }, i: number) =>
                canIview ? (
                  <CanIView key={i} component={canIview}>
                    <HeaderButton {...others} />
                  </CanIView>
                ) : (
                  <HeaderButton {...others} key={i} />
                ),
              )}
            </div>
          </div>
        </div>
        <div>
          <Separator className='my-4' />
        </div>

        <div className='flex flex-wrap justify-end gap-5  '>
          {/* mt-8 mb-6 md:px-8 */}
          <div className='flex w-full justify-between p-5 gap-2 '>
            {(user.roles === UserRoles.Ogma || user.roles === UserRoles.Student) && (
              <div className='flex items-baseline'>
                <div
                  className='button hover:bg-opacity-80 w-fit bg-fen-blue'
                  onClick={() => {
                    setShowMy(!showMy);
                  }}
                >
                  {showMy ? 'Show All Teams' : 'Show My Teams'}
                </div>
              </div>
            )}
            {/* </div> */}
          </div>
          <div className='mr-auto'>
            <span>Show: </span>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant='outline'>{teamStatusFilterMode}</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className='w-56'>
                <DropdownMenuLabel>Filter Teams</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuRadioGroup
                  value={teamStatusFilterMode}
                  onValueChange={setTeamStatusFilterMode}
                >
                  <DropdownMenuRadioItem value='All Teams'>All Teams</DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value='Complete Teams'>
                    Complete Teams
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value='Incomplete Teams'>
                    Incomplete Teams
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <Search
            setQuery={setSearchQuery}
            filterList={filterList}
            search={search}
            setSearch={setSearch}
          />
          {/* </div> */}
        </div>
        <div className='flex flex-col flex-1 justify-between'>
          <TableContainer>
            <TableHead
              oneMore
              tableList={listView
                .map((l) => l.name)
                .filter(
                  (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
                )}
            />
            <tbody className='bg-white divide-y divide-gray-200 '>
              {teamData?.data?.map((team: any, i: number) => (
                <tr
                  onClick={(e) =>
                    user.roles === UserRoles.Admin
                      ? handleClick({ id: team.id, x: e.pageX, y: e.pageY })
                      : navigate(`/teams/view/${team.id}`)
                  }
                  className={user.roles !== UserRoles.Admin ? 'cursor-pointer ' : 'relative '}
                  key={i}
                >
                  {listView
                    .filter((l) => l.active)
                    .map((t) => {
                      const model: TeamModel & { [key: string]: any } = new TeamModel(team);
                      return t.property === 'name' ? (
                        <TableTd key={t.name + i} extraClass='max-w-[300px]'>
                          <NameAndAvatar
                            image={model.schoolImage}
                            name={model.name ? model.name : ''}
                          />
                        </TableTd>
                      ) : t.property === 'school' ? (
                        <TableTd key={t.name + i} extraClass='max-w-[15ch]'>
                          <div className='text-sm font-medium text-gray-900 truncate'>
                            {model.school}
                          </div>
                        </TableTd>
                      ) : t.property === 'numberOfStudents' ? (
                        <TableTd key={t.name + i} extraClass='max-w-[15ch]'>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>{model[t.property]}</TooltipTrigger>
                              <TooltipContent>{`${
                                model[t.property]
                              } total players including subs.`}</TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </TableTd>
                      ) : t.property === 'sizeStatus' ? (
                        <TableTd key={t.name + i} extraClass='max-w-[15ch]'>
                          <TooltipProvider>
                            <Tooltip>
                              <div className='inline-flex'>
                                {model.numberOfActiveMembers === 0 ? (
                                  <>
                                    <TooltipTrigger>
                                      <TbProgressAlert color='red' size={'2em'} />
                                    </TooltipTrigger>
                                    <TooltipContent>Team has no players</TooltipContent>
                                  </>
                                ) : model.numberOfActiveMembers < team.Game?.active_limit ? (
                                  <>
                                    <TooltipTrigger>
                                      <TbProgressAlert color='#ffcc00' size={'2em'} />
                                    </TooltipTrigger>
                                    <TooltipContent>{`Team has ${model.numberOfActiveMembers} of ${team.Game?.active_limit} starters`}</TooltipContent>
                                  </>
                                ) : (
                                  <>
                                    <TooltipTrigger>
                                      <TbProgressCheck color='green' size={'2em'} />
                                    </TooltipTrigger>
                                    <TooltipContent>Team is complete</TooltipContent>
                                  </>
                                )}
                              </div>
                            </Tooltip>
                          </TooltipProvider>
                        </TableTd>
                      ) : t.property === 'season' ? (
                        <TableTd>
                          {model.activeSeasons
                            ?.slice()
                            ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                            .map((season: any) => {
                              return (
                                <div
                                  key={season.id}
                                  className='hover:text-black hover:bg-opacity-20 hover:font-bold hover:underline text-center'
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`../seasons/view/${season.id}`);
                                  }}
                                >
                                  {season.name}
                                </div>
                              );
                            })}
                        </TableTd>
                      ) : (
                        <TableTd key={t.name + i}>{model[t.property]}</TableTd>
                      );
                    })}

                  <TableTd>
                    <div className='listViewMenu--parent'>
                      <div
                        className={openList.id === team.id ? '' : 'hidden'}
                        style={
                          openList.id === team.id
                            ? {
                                position: 'fixed',
                                top: Number(openList.y),
                                left:
                                  Number(openList.x) < 200
                                    ? Number(openList.x) + 200
                                    : Number(openList.x),
                                zIndex: 99,
                              }
                            : {
                                left: 0,
                              }
                        }
                      >
                        <div
                          className='listViewMenu'
                          role='menu'
                          aria-orientation='vertical'
                          aria-labelledby='menu-button'
                        >
                          <div className='' role='none'>
                            <DropdownItem
                              isTeam
                              isBenched={team?.isBenched}
                              id={team.id}
                              setFunc={setOpenList}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableTd>
                </tr>
              ))}
            </tbody>
          </TableContainer>
          <Outlet />
          <Pagination length={teamData?.data?.length} {...teamData?.meta} />
          {/* {showModal ? <Modal /> : null} */}
        </div>
      </div>
    </>
  );
};

export default Teams;
