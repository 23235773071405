import { useGetTeamMatchesBySeasonIdQuery } from '../../../../../../slices/matchApiSlice';
import { useViewSingleSeasonQuery } from '../../../../../../slices/seasonApiSlice';
import { MatchTeamAway, MatchTeamHome, ViewMatchDetails } from '../../../../../match/components';
import { LeaderboardSeasonRow } from '../../../../../view-season/components/season-matches';

type TeamMatchHistoryProps = {
  teamId: number;
  seasonId: string | undefined;
};
function TeamMatchHistory({ teamId, seasonId }: TeamMatchHistoryProps) {
  const { data, isLoading } = useGetTeamMatchesBySeasonIdQuery(
    { teamId, seasonId },
    { skip: !seasonId },
  );
  const { data: seasonData, isLoading: seasonIsLoading } = useViewSingleSeasonQuery(seasonId, {
    skip: !seasonId,
  });
  console.log('data', data);
  console.log('seasondata', seasonData);
  const now = new Date().toISOString();
  const pastTeamMatchesByStage = data?.reduce((acc: any, match: any) => {
    const stageName = match.SeasonWeek?.SeasonStage?.name;

    if (!stageName) return acc;

    if (match.match_date < now) {
      if (!acc[stageName]) {
        acc[stageName] = { stageName, matches: [] };
      }

      acc[stageName].matches.push(match);
    }
    return acc;
  }, {});

  return (
    <div>
      {pastTeamMatchesByStage &&
        Object.values(pastTeamMatchesByStage)?.map((stage: any) => {
          return (
            <>
              <h1 className='text-xl font-bold border-b-2 border-black w-fit mt-4'>
                {stage.stageName}
              </h1>
              {stage.matches?.map((match: any) => {
                return (
                  <div key={match.id} className='flex flex-row gap-4 mb-2 items-center'>
                    {!seasonData?.SeasonType.leaderboard ? (
                      <ViewMatchDetails
                        key={match.id}
                        start_date={match.match_date}
                        size='xl'
                        home_team_length={match.home_team.length}
                        home_team={match.home_team}
                        status={match.status}
                        away_team_length={match.away_team.length}
                        away_team={match.away_team}
                        match_date={match.match_date}
                        isMatch={false}
                        {...match}
                      />
                    ) : (
                      <LeaderboardSeasonRow
                        key={match.id}
                        rank={0}
                        seasonWeekStatus={match.SeasonWeek.status}
                        matchStatus={match.status}
                        teamName={match.home_team?.[0]?.teamName}
                        schoolName={match.home_team?.[0]?.school?.name ?? 'CO-OP'}
                        organizationName={match.home_team?.[0]?.organizationName ?? 'CO-OP'}
                        matchId={match.id}
                        score={Number(match.ht_score)}
                        teamId={match.home_team?.[0]?.id}
                        hasScreenshots={false}
                        seasonStatus={seasonData?.status}
                        teamUsers={match?.home_team?.[0]?.User.flatMap((user: any) =>
                          user.InGameName?.flatMap((inGameName: any) =>
                            inGameName.game_id === match.game_id ? inGameName : [],
                          ),
                        )}
                        seasonProgramType={seasonData?.program_type}
                      />
                    )}
                  </div>
                );
              })}
            </>
          );
        })}
    </div>
  );
}

export default TeamMatchHistory;
