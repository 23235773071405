import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AvatarImage } from '../../../../components/avatar-image';
import TableTd from '../../../../components/table-td/table-td';
import { useGetAllTeamsBySchoolIdQuery } from '../../../../slices/schoolsApiSlice';
import { dateAndTimeFormatter } from '../../../../utils/dateFormatter';

export function OrganizationTeamsBySchool({
  id,
  active,
  sName,
}: {
  id: string;
  active: boolean;
  sName?: string;
}) {
  const param = useParams();

  // const {
  //     data: organizationData,
  //     isLoading: organizationIsLoading,
  //     isError: organizationIsError,
  //     isSuccess: organizationIsSuccess,
  // } = useGetOrgByIdQuery(param.id);

  const {
    data: teamsData,
    isLoading: teamsIsLoading,
    isError: teamsIsError,
    isSuccess: teamsIsSuccess,
  } = useGetAllTeamsBySchoolIdQuery(id);

  useEffect(() => {
    console.log('asdasdsad');
    console.log(teamsData);
  });

  const navigate = useNavigate();

  function timeAndDateFormatt(created: any) {
    throw new Error('Function not implemented.');
  }

  return (
    <>
      {active ? (
        // <div className='flex justify-center'>
        <>Number of Teams - {teamsData && teamsData?.length}</>
      ) : (
        // </div>
        teamsData &&
        teamsData.map((team: any, idx: number) => (
          <tr onClick={() => navigate(`/teams/view/${team.id}`)} key={idx}>
            <TableTd>
              <AvatarImage size='xl' source={team.School?.image} />
            </TableTd>
            <TableTd extraClass='max-w-[15ch] truncate'>{team.teamName}</TableTd>
            <TableTd>{dateAndTimeFormatter(team.created).split(';')[0]}</TableTd>
            <TableTd>{team?.User?.length}</TableTd>
            <TableTd extraClass='max-w-[15ch] truncate'>{sName}</TableTd>
            <TableTd extraClass='max-w-[15ch] truncate'>{team.Game.name}</TableTd>
          </tr>
        ))
      )}
    </>
  );
}
