import { useNavigate } from 'react-router-dom';
import { AvatarImage } from '../../../../../components/avatar-image';
import { MatchScheduleModel } from '../../../../../model/match-schedule.model';
import { dateAndTimeFormatter, timeZoneTimeFormatter } from '../../../../../utils/dateFormatter';
import { getTypeOfMatchFormat } from '../../../../../utils/utils';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../slices/auth';
import { useEffect, useState } from 'react';
import { UserRoles } from '../../../../../utils/constants';

const MatchScheduleView = ({
  scheduleModel,
  fullDate,
  fromDashboard,
}: {
  scheduleModel: MatchScheduleModel;
  fullDate?: boolean;
  fromDashboard?: boolean;
}) => {
  const navigate = useNavigate();

  const MatchSection = ({
    eventType,
    image,
    name,
    game,
  }: {
    eventType: string;
    image: string;
    name: string;
    game: string;
  }) => {
    return (
      <div className='flex gap-x-4 items-center rounded-l-full bg-gray-100  hover:border-t-2 hover:border-b-2 w-full overflow-hidden'>
        <AvatarImage source={image} size='l' />
        <div className='w-[20ch]'>
          <p className='text-sm truncate'>
            <span className='font-semibold'>{eventType}:</span> {name}
          </p>
          <p className='text-xs truncate'>
            <span className='font-semibold'>Game:</span> {game}
          </p>
        </div>
      </div>
    );
  };

  const user = useSelector(selectUser);
  const [part, setPart] = useState({
    home: false,
    away: false,
  });

  useEffect(() => {
    if (scheduleModel) {
      if (user?.roles === UserRoles?.Admin) {
        const homeBenched = scheduleModel?.home_team?.[0]?.isBenched;
        const awayBenched = scheduleModel?.away_team?.[0]?.isBenched;

        if (homeBenched) setPart((prevState: any) => ({ ...prevState, home: true }));
        else setPart((prevState: any) => ({ ...prevState, home: false }));

        if (awayBenched) setPart((prevState: any) => ({ ...prevState, away: true }));
        else setPart((prevState: any) => ({ ...prevState, away: false }));
      }

      if (user?.roles === UserRoles.Student) {
        const homePart =
          scheduleModel?.home_team?.[0]?.activeMembers?.includes(user.id) ||
          scheduleModel?.home_team?.[0]?.subMembers?.includes(user.id);

        if (homePart && scheduleModel?.home_team?.[0]?.isBenched)
          setPart((prevState: any) => ({ ...prevState, home: true }));
        else setPart((prevState: any) => ({ ...prevState, home: false }));

        const awayPart =
          scheduleModel?.away_team?.[0]?.activeMembers?.includes(user.id) ||
          scheduleModel?.away_team?.[0]?.subMembers?.includes(user.id);

        if (awayPart && scheduleModel?.away_team?.[0]?.isBenched)
          setPart((prevState: any) => ({ ...prevState, away: true }));
        else setPart((prevState: any) => ({ ...prevState, away: false }));
      }

      if (user?.roles === UserRoles.Ogma) {
        const home_team = scheduleModel?.home_team;
        const away_team = scheduleModel?.away_team;

        const homeOgmas = [
          home_team?.[0]?.organizations?.map((org: any) => org.user?.id),
          home_team?.[0]?.School?.organization?.user?.id,
          ...(home_team?.[0]?.organizations?.map((org: any) =>
            org.ogmas?.map((o: { id: number }) => o.id),
          ) ?? []),
          ...(home_team?.[0]?.School?.organization?.ogmas?.map((o: { id: number }) => o.id) ?? []),
        ].flat();

        if (homeOgmas?.includes(user?.id) && home_team?.[0]?.isBenched)
          setPart((prevState: any) => ({ ...prevState, home: true }));
        else setPart((prevState: any) => ({ ...prevState, home: false }));

        const awayOgmas = [
          away_team?.[0]?.organizations?.map((org: any) => org.user?.id),
          away_team?.[0]?.School?.organization?.user?.id,
          ...(away_team?.[0]?.organizations?.map((org: any) =>
            org.ogmas?.map((o: { id: number }) => o.id),
          ) ?? []),
          ...(away_team?.[0]?.School?.organization?.ogmas?.map((o: { id: number }) => o.id) ?? []),
        ].flat();

        if (awayOgmas?.includes(user?.id) && away_team?.[0]?.isBenched)
          setPart((prevState: any) => ({ ...prevState, away: true }));
        else setPart((prevState: any) => ({ ...prevState, away: false }));
      }
    }
  }, [scheduleModel]);

  return (
    <>
      {fullDate ? (
        <div className='flex justify-between items-center p-2 '>
          {dateAndTimeFormatter(scheduleModel.start_date)}
        </div>
      ) : (
        <></>
      )}
      <div
        className='flex  items-center gap-x-5 flex-wrap  border rounded-xl justify-between hover:cursor-pointer hover:bg-gray-100
sm:justify-center
      '
        onClick={() => navigate(`/match/${scheduleModel?.id}`)}
      >
        <div className='flex justify-between items-center p-2 '>
          <p className='whitespace-nowrap'>
            {!fullDate ? timeZoneTimeFormatter(scheduleModel.start_date) : <></>}
          </p>
          <div className='border-r-2 p-2 mx-4 border-gray-800 schedule-media'></div>
          <div className='p-2 text-xs font-semibold schedule-media'>
            <p>
              {getTypeOfMatchFormat(scheduleModel)}
              {/* {scheduleModel.tournamentMatchFormat
                ? scheduleModel.tournamentMatchFormat
                : scheduleModel.seasonMatchFormat} */}
            </p>
          </div>
        </div>
        <div className='p-2 flex justify-center items-center gap-4 min-w-[1px] w-full md:w-fit md:flex-1'>
          <div className='flex justify-end gap-4 items-center overflow-hidden text-end font-semibold w-[calc(50%-100px)] max-w-[calc(50%-100px)] min-w-[calc(50%-100px)]'>
            {/* <span className='truncate'>{scheduleModel.home_teamName}</span> */}
            {(user?.roles === UserRoles.Admin ||
              user?.roles === UserRoles?.Ogma ||
              user?.roles === UserRoles.Student) &&
            part?.home ? (
              <div className='flex flex-col sm:flex-row items-center gap-2 truncate'>
                <div>
                  <span className='text-[#B50303] font-[600] font-[Inter] text-[12px]'>
                    BENCHED
                  </span>
                </div>
                <div>
                  <p className='text-[#424242] truncate'>{scheduleModel.home_teamName}</p>
                </div>
              </div>
            ) : (
              <span className='truncate'>{scheduleModel.home_teamName}</span>
            )}
            {/* {user?.roles === UserRoles?.Admin ? (
               ? (
                <div className='flex items-center gap-2'>
                  <div>
                    <span className='text-[#B50303] font-[600] font-[Inter] text-[12px]'>
                      BENCHED
                    </span>
                  </div>
                  <div>
                    <p className='text-[#424242] truncate'>{scheduleModel.home_teamName}</p>
                  </div>
                </div>
              ) : (user?.roles === UserRoles.Ogma || user?.roles === UserRoles.Student) &&
                part?.home ? (
                <div className='flex items-center gap-2'>
                  <div>
                    <span className='text-[#B50303] font-[600] font-[Inter] text-[12px]'>
                      BENCHED
                    </span>
                  </div>
                  <div>
                    <p className='text-[#424242] truncate'>{scheduleModel.home_teamName}</p>
                  </div>
                </div>
              ) : (
                <p className='text-[#424242] truncate'>{scheduleModel.home_teamName}</p>
              )
            ) : (
              <span className='truncate'>{scheduleModel.home_teamName}</span>
            )} */}
            <AvatarImage
              source={scheduleModel.home_teamSchoolImage!}
              size='xl'
              extraClass='max-[1000px]:hidden'
            />
          </div>
          <div className='flex justify-center items-center gap-2 px-4 w-[100px] max-w-[100px] min-w-[100px]'>
            <p className='text-xl'>{scheduleModel.ht_score} </p>

            {Number(scheduleModel.season_type) || scheduleModel.tournament_round_id === null ? (
              <></>
            ) : (
              <>
                <p>vs</p>
                <p className='text-xl'>{scheduleModel.at_score}</p>
              </>
            )}
          </div>
          <div className='flex justify-start gap-4 items-center text-start font-semibold w-[calc(50%-100px)] max-w-[calc(50%-100px)] min-w-[calc(50%-100px)]'>
            {Number(scheduleModel.season_type) || scheduleModel.tournament_round_id === null ? (
              <></>
            ) : (
              <>
                <AvatarImage
                  source={scheduleModel.away_teamSchoolImage!}
                  size='xl'
                  extraClass='max-[1000px]:hidden'
                />
                {/* <span className='truncate'>{scheduleModel.away_teamName}</span> */}
                {/* {scheduleModel?.away_team_benched ? (
                  user?.roles === UserRoles?.Admin ? (
                    <div className='flex items-center gap-2'>
                      <div>
                        <p className='text-[#424242] truncate'>{scheduleModel.away_teamName}</p>
                      </div>
                      <div>
                        <span className='text-[#B50303] font-[600] font-[Inter] text-[12px]'>
                          BENCHED
                        </span>
                      </div>
                    </div>
                  ) : (user?.roles === UserRoles.Ogma || user?.roles === UserRoles.Student) &&
                    part?.away ? (
                    <div className='flex items-center gap-2'>
                      <div>
                        <p className='text-[#424242] truncate'>{scheduleModel.away_teamName}</p>
                      </div>
                      <div>
                        <span className='text-[#B50303] font-[600] font-[Inter] text-[12px]'>
                          BENCHED
                        </span>
                      </div>
                    </div>
                  ) : (
                    <p className='truncate'>{scheduleModel.away_teamName}</p>
                  )
                ) : (
                  <p className='truncate'>{scheduleModel.away_teamName}</p>
                )} */}
                {(user?.roles === UserRoles.Admin ||
                  user?.roles === UserRoles.Ogma ||
                  user?.roles === UserRoles.Student) &&
                part?.away ? (
                  <div className='flex flex-col sm:flex-row items-center gap-2 truncate'>
                    <div>
                      <p className='text-[#424242] truncate'>{scheduleModel.away_teamName}</p>
                    </div>
                    <div>
                      <span className='text-[#B50303] font-[600] font-[Inter] text-[12px]'>
                        BENCHED
                      </span>
                    </div>
                  </div>
                ) : (
                  <p className='truncate'>{scheduleModel.away_teamName}</p>
                )}
              </>
            )}
          </div>
        </div>
        <div
          className='max-[1530px]:hidden w-[250px]'
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {scheduleModel.tournamentId ? (
            <div
              className='cursor-pointer'
              onClick={(e) => {
                navigate(`/tournaments/view/${scheduleModel.tournamentId}`);
              }}
            >
              <MatchSection
                eventType='Tournament'
                image={scheduleModel.tournamentImage!}
                name={scheduleModel.tournamentName!}
                game={scheduleModel.gameName!}
              />
            </div>
          ) : (
            <div
              className='cursor-pointer'
              onClick={(e) => {
                navigate(`/seasons/view/${scheduleModel.seasonId}`);
              }}
            >
              <MatchSection
                eventType='Season'
                image={scheduleModel.seasonImage!}
                name={
                  scheduleModel.seasonName!.length >= 10
                    ? scheduleModel.seasonName?.slice(0, 10) + '...'
                    : scheduleModel.seasonName!
                }
                game={scheduleModel.gameName!}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MatchScheduleView;
