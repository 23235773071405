import { useGetAllClassroomsByOrganizationIdQuery } from '../../slices/classroomApiSlice';
import { Link, Outlet, useParams, useSearchParams } from 'react-router-dom';
import { ClassroomModel } from '../../model/classroom.model';
import ClassroomCard from './components/classroom-card';
import { ClassRoomCardLoadingSceleton } from './components/classroom-card-loading-sceleton';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import Pagination from '../../components/pagination';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';
import {
  SeasonProgramTypes,
  SponsorPlacements,
  ClassroomContentBySeasonProgramType,
  UserRoles,
} from '../../utils/constants';
import { NoContentMessages } from '../../utils/messages.enum';
import { useState } from 'react';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { useGetUserActiveSeasonTypesQuery } from '../../slices/userApiSlice';

const AllClassrooms = () => {
  const show = false;
  const user = useSelector(selectUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showDroneRacingContent, setShowDroneRacingContent] = useState(true);
  const { data, isLoading, isError } = useGetAllClassroomsByOrganizationIdQuery({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
  });
  const { data: userSeasonTypesData, isLoading: userSeasonTypesDataLoading } =
    useGetUserActiveSeasonTypesQuery(user?.id);
  if (isLoading)
    return (
      <div className='flex listViewContainer fullPage border-1 bg-white !flex-row flex-wrap rounded-xl'>
        {Object.keys(Array.from(new Array(8))).map((t: string) => (
          <ClassRoomCardLoadingSceleton key={t} />
        ))}
      </div>
    );
  // TODO: REMOVE THIS RETURN TO SHOW CLASSROOMS
  if (!show)
    return (
      <div className='flex gap-10 flex-col justify-center items-center'>
        {(userSeasonTypesData?.programTypes.includes(SeasonProgramTypes.DroneRacing) ||
          user.roles === UserRoles.Admin) && (
          <div className='border-black border-2 pl-4 w-full align-center'>
            <div
              className='flex justify-between w-full items-center cursor-pointer'
              onClick={() => {
                setShowDroneRacingContent(!showDroneRacingContent);
              }}
            >
              <div className='text-2xl font-bold items-center'>Drone Racing</div>
              <div>{!showDroneRacingContent ? <GoTriangleDown /> : <GoTriangleUp />}</div>
            </div>
            {showDroneRacingContent && (
              <div className='border-t-2 flex flex-col col-3'>
                <div className='p-4'>
                  <Program
                    programType={
                      ClassroomContentBySeasonProgramType[SeasonProgramTypes.DroneRacing]
                        .seasonProgramType
                    }
                    stages={
                      ClassroomContentBySeasonProgramType[SeasonProgramTypes.DroneRacing].stages
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  return (
    <>
      <Outlet />
      {/* <SponsorRow name={SponsorPlacements.Classrooms} /> */}
      <div className='flex listViewContainer fullPage border-1 bg-white rounded-xl'>
        {/* <h1 className='text-2xl font-semibold tracking-tight'> My Classrooms</h1> */}
        <SponsorRow name={SponsorPlacements.Classrooms} />

        <div className='flex h-full flex-col justify-between'>
          {!data?.data?.length && (
            <div className='flex-1 flex justify-center'>{NoContentMessages.Classrooms}</div>
          )}
          <div className='flex  flex-wrap justify-center gap-4 mt-4'>
            {data?.data &&
              data?.data?.map((c: any, i: number) => (
                <Link to={`./view/${c.id}`} key={i}>
                  <div>
                    <ClassroomCard classroom={new ClassroomModel(c)} />
                  </div>
                </Link>
              ))}
            <Pagination length={data?.data?.length} {...data?.meta} />
          </div>
          {/* <Pagination length={data?.data?.length} {...data?.meta} /> */}
        </div>
      </div>
    </>
  );
};

const Program = ({ programType, stages }: { programType: number; stages: any[] }) => (
  <div>
    {stages.map((stage, index) => (
      <Stage key={index} seasonStage={stage.seasonStage} sections={stage.sections} />
    ))}
  </div>
);

const Stage = ({ seasonStage, sections }: { seasonStage: string; sections: any[] }) => (
  <div className='mb-8'>
    <h2 className='text-2xl font-bold mb-4'>{seasonStage}</h2>
    {sections.map((section, index) => (
      <Section key={index} sectionTitle={section.sectionTitle} videos={section.videos} />
    ))}
  </div>
);

const Video = ({ title, videoId }: { title: string; videoId: string }) => {
  const [isIframeLoaded, setIframeLoaded] = useState(false);

  return (
    <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-6 flex flex-col items-center'>
      {!isIframeLoaded ? (
        <div
          className='relative w-full aspect-video bg-black rounded-md cursor-pointer group'
          onClick={() => setIframeLoaded(true)}
        >
          <img
            className='absolute inset-0 w-full h-full object-cover rounded-md group-hover:opacity-75'
            src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
            alt={`${title} Thumbnail`}
          />
          <div className='absolute inset-0 flex items-center justify-center'>
            <div className='w-12 h-12 bg-white rounded-full flex items-center justify-center group-hover:scale-110 transition-transform'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-6 h-6 text-black'
                fill='currentColor'
                viewBox='0 0 24 24'
              >
                <path d='M8 5v14l11-7z' />
              </svg>
            </div>
          </div>
        </div>
      ) : (
        <iframe
          className='w-full aspect-video rounded-md'
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          title={title}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
      )}
      <h4 className='text-lg font-medium mt-2'>{title}</h4>
    </div>
  );
};

const Section = ({ sectionTitle, videos }: { sectionTitle: string; videos: any[] }) => {
  const getVideoId = (url: string) => {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/([a-zA-Z0-9_-]{11})))|(?:youtu\.be\/([a-zA-Z0-9_-]{11}))/,
    );
    return match ? match[1] || match[2] : null;
  };
  return (
    <div className='mb-6'>
      <h3 className='text-xl font-semibold mb-3'>{sectionTitle}</h3>
      <div className='flex flex-wrap -mx-4'>
        {videos.map((video, index) => {
          const videoId = getVideoId(video.url);
          return videoId ? <Video key={index} title={video.title} videoId={videoId} /> : null;
        })}
      </div>
    </div>
  );
};

export default AllClassrooms;
