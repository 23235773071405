import { SeasonWeekDateInfo } from './season-week-date-info';
import { useEffect, useState } from 'react';
import { Link, useNavigate, Outlet, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { useGetAllMatchesByWeekIdQuery, useRearrangeMutation } from '../../../slices/matchApiSlice';
import { ViewMatchDetails } from '../../match/components/view-match-details';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import CanIView from '../../../components/can-i-view/can-i-view';
import {
  useCanSubmitScoreQuery,
  useCloseSeasonWeekMatchMutation,
  useGetSeasonWeekByIdQuery,
  useOpenSeasonWeekMutation,
  useSubmitScoresByCsvMutation,
  useSubmitScoreSeasonWeekMutation,
} from '../../../slices/seasonApiSlice';
import { SeasonWeekModel } from '../../../model/season-week.model';
import { SeasonWeekMatchModel } from '../../../model/season-week-match.model';
import { Form, Formik, FormikValues } from 'formik';
import {
  Games,
  MatchStatus,
  SeasonProgramTypes,
  SeasonProgressStatus,
  SeasonWeekStatus,
  UserRoles,
} from '../../../utils/constants';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { toast } from 'react-toastify';
import { PublishSeasonWeekModal } from './publish-season-week.component';
import { CloseSeasonWeekModal } from './close-season-week.component';
import { FormInput } from '../../../components/form-input/form-input';
import { SubmitButton } from '../../../components/submit-button';
import SeeAllScreenshoots from './see-all-screenshots.components';
import { ToastMessages } from '../../../utils/modal-messages';
import ModalContent from '../../../components/modal-content';
import { OutsideClick } from '../../../components/outside-click';
import { useGetGameByIdQuery } from '../../../slices/gameApiSlice';

export function SeasonMatches({
  selectedWeek = undefined,
  htSchool = '',
  atSchool = '',
  bgColor = '',
  seasonStatus = undefined,
  seasonStageGroups,
}: {
  htSchool?: string;
  atSchool?: string;
  bgColor?: string;
  selectedWeek: false | undefined | number | string;
  seasonStatus: number | any;
  seasonStageGroups?: any;
}) {
  const {
    isLoading,
    data: matchData,
    isError,
  } = useGetAllMatchesByWeekIdQuery(selectedWeek, { skip: !selectedWeek });
  const {
    data: seasonWeekData,
    isLoading: isLoadingSW,
    isError: isErroSW,
    error: erroSW,
  } = useGetSeasonWeekByIdQuery(selectedWeek, { skip: !selectedWeek });
  const { data: gameData } = useGetGameByIdQuery(seasonWeekData?.Season?.game_id?.toString(), {
    skip: !seasonWeekData?.Season?.game_id,
  });
  const [trOpenSw, { data: oSwData, isLoading: oSwIsLoading, isSuccess: oSwIsSuccess }] =
    useOpenSeasonWeekMutation();

  const [submitCsvScores, { isLoading: submitCsvIsLoading, isSuccess: submitCsvIsSuccess }] =
    useSubmitScoresByCsvMutation();

  const [editMode, setEditMode] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [submitCsvModal, setSubmitCsvModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [csvFile, setCsvFile] = useState<any>();
  const [errorMsg, setErrorMsg] = useState('');
  // useEffect(() => {
  //   if (selectedWeek) trigger(selectedWeek);
  // }, [selectedWeek]);
  const [rearrange, { isLoading: upLoading }] = useRearrangeMutation();
  const navigate = useNavigate();

  const formInputs = [
    {
      name: 'CSV',
      label: 'CSV File',
      type: 'image',
      placeholder: 'File',
      min: 0,
      isTrue: true,
    },
  ];

  const SubmitCsvScoresVSchema = yup.object().shape({
    CSV: yup.mixed().nullable(),
  });
  function onSubmit(values: FormikValues) {
    if (!csvFile) {
      setErrorMsg('Please select a CSV!');
      return;
    }
    const formData = new FormData();
    formData.append('csvFile', csvFile);
    submitCsvScores({ seasonWeekId: +sW.id!, body: formData });
    values.score = '';
  }

  useEffect(() => {
    if (csvFile) setErrorMsg('');
  }, [csvFile]);

  function handleDragEnd(event: DragEndEvent) {
    const { active: rawActive, over: rawOver } = event;
    console.log({ rawActive, rawOver });
    const active = JSON.parse((rawActive.id as string) || '[]');
    const over = JSON.parse((rawOver?.id as string) || '[]');
    if (!over.length || active[0] === over[0]) return;
    console.log(over);
    const data = {
      first: { id: active[0], matchId: active[1], team: active[2] },
      second: { id: over[0], matchId: over[1], team: over[2] },
    };
    rearrange({ body: data, weekId: Number(selectedWeek) });
  }

  const [searchParam, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (oSwIsSuccess) toast.success(ToastMessages.OpenSeasonWeek, { position: 'top-right' });
  }, [oSwIsSuccess]);

  useEffect(() => {
    if (!submitCsvIsSuccess) return;
    toast.success('Successfuly uploaded scores', { position: 'top-right' });
    setSubmitCsvModal(false);
  }, [submitCsvIsSuccess]);

  if (isLoading || isLoadingSW) return <p>Loading ... </p>;
  const sW = new SeasonWeekModel(seasonWeekData);
  return (
    <div className='flex flex-col relative bg-white w-full items-center gap-10'>
      <div className='flex w-full items-center justify-between'>
        {sW?.f_start_time?.length ? <SeasonWeekDateInfo seasonWeek={sW} /> : <></>}
        {sW.status === SeasonWeekStatus.NotStarted &&
        (sW.isLeaderboard || matchData?.seasonProgramType === SeasonProgramTypes.DroneRacing) ? (
          <p className='text-center font-bold border rounded-xl p-2'>Week Has Not Started</p>
        ) : (
          <></>
        )}
        <div className='flex gap-2'>
          {sW.isLeaderboard ? (
            sW.status == SeasonWeekStatus.Open &&
            seasonStatus !== SeasonProgressStatus.Completed ? (
              <>
                <CanIView component='season.rearrange'>
                  <div
                    className={'filterBtn cursor-pointer select-none'}
                    onClick={() => setCloseModal(true)}
                  >
                    Close Week
                  </div>
                </CanIView>
              </>
            ) : (
              <></>
            )
          ) : selectedWeek &&
            matchData?.data?.length &&
            seasonStatus !== SeasonProgressStatus.Completed ? (
            <>
              {matchData?.data?.length ? (
                <CanIView component='season.rearrange'>
                  <div
                    className={'filterBtn cursor-pointer select-none'}
                    onClick={() => setEditMode((s) => !s)}
                  >
                    {editMode ? 'Exit' : 'Rearrange'}
                  </div>
                </CanIView>
              ) : (
                <></>
              )}
              {editMode ? (
                <CanIView component='season.rearrange'>
                  <Link
                    state={{ week: searchParam.get('week') }}
                    to='./add-match'
                    className='filterBtn cursor-pointer select-none'
                  >
                    Add Match
                  </Link>
                </CanIView>
              ) : (
                <></>
              )}
              {matchData?.seasonProgramType === SeasonProgramTypes.DroneRacing &&
                sW.status === SeasonWeekStatus.Open && (
                  <CanIView component='season.rearrange'>
                    <div
                      className={'filterBtn cursor-pointer select-none'}
                      onClick={() => setCloseModal(true)}
                    >
                      Close Week
                    </div>
                  </CanIView>
                )}
            </>
          ) : (
            <></>
          )}
          {(sW.isLeaderboard || matchData?.seasonProgramType === SeasonProgramTypes.DroneRacing) &&
          matchData?.data?.filter((d: any) => d.status !== MatchStatus.Completed).length &&
          sW.status === SeasonWeekStatus.NotStarted &&
          seasonStatus !== SeasonProgressStatus.Completed ? (
            <CanIView component='season.rearrange'>
              <div
                className={'filterBtn cursor-pointer select-none'}
                onClick={() => trOpenSw(Number(sW.id))}
              >
                {oSwIsLoading ? 'Opening...' : 'Open Week'}
              </div>
            </CanIView>
          ) : (
            <></>
          )}

          {sW.isLeaderboard ||
          (matchData?.seasonProgramType === SeasonProgramTypes.DroneRacing &&
            sW.status === SeasonWeekStatus.Closed) ? (
            <>
              {' '}
              {sW.status !== SeasonWeekStatus.Published &&
              seasonStatus !== SeasonProgressStatus.Completed ? (
                <>
                  <CanIView component='season.rearrange'>
                    <div
                      className={'filterBtn cursor-pointer select-none'}
                      onClick={() => setPublishModal(true)}
                    >
                      Publish Week
                    </div>
                  </CanIView>
                  {gameData &&
                    gameData?.name
                      .toLocaleLowerCase()
                      .includes(Games.Fortnite.toLocaleLowerCase()) && (
                      <CanIView component='season.rearrange'>
                        <div
                          className={'filterBtn cursor-pointer select-none'}
                          onClick={() => setSubmitCsvModal(true)}
                        >
                          Submit Scores by CSV
                        </div>
                      </CanIView>
                    )}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        {closeModal ? (
          <CloseSeasonWeekModal
            id={Number(sW.id)}
            cancel={() => setCloseModal(false)}
            name={sW.name ?? ''}
          />
        ) : (
          <></>
        )}

        {publishModal ? (
          <PublishSeasonWeekModal
            id={Number(sW.id)}
            cancel={() => setPublishModal(false)}
            name={sW.name ?? ''}
          />
        ) : (
          <></>
        )}
        {submitCsvModal ? (
          <>
            <OutsideClick setFunc={() => setSubmitCsvModal(false)} />
            <Formik
              initialValues={{ CSV: null }}
              validationSchema={SubmitCsvScoresVSchema}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {({ isSubmitting, values, errors }) => {
                return (
                  <ModalContent
                    name={`Submit Scores by CSV`}
                    setAvatarImage={setCsvFile}
                    formInputs={formInputs}
                    isLoading={submitCsvIsLoading}
                    extraClass='h-fit'
                    errors={Object.keys(errors).length}
                    isError={Boolean(errorMsg)}
                    error={errorMsg}
                    onCancel={() => setSubmitCsvModal(false)}
                  />
                );
              }}
            </Formik>
          </>
        ) : (
          <></>
        )}
      </div>
      {selectedWeek ? (
        sW.isLeaderboard ? (
          <div className='flex flex-col gap-2 min-w-[600px]'>
            {seasonStageGroups && matchData ? (
              seasonStageGroups.map((group: any, i: number) => {
                const groupMatches = matchData?.data
                  .filter((match: any) =>
                    group.teams.flatMap((team: any) => team.id).includes(match.home_team[0].id),
                  )
                  .slice()
                  ?.sort((a: any, b: any) => {
                    if (sW.status !== SeasonWeekStatus.Published) {
                      return a.home_team[0].teamName
                        .toLowerCase()
                        .localeCompare(b.home_team[0].teamName.toLowerCase());
                    }
                    if (a.ht_score === 0) return 1;
                    if (b.ht_score === 0) return -1;
                    return matchData?.seasonProgramType !== SeasonProgramTypes.DroneRacing
                      ? b.ht_score - a.ht_score
                      : a.ht_score - b.ht_score;
                  });

                return (
                  <div>
                    <h2 className='text-xl font-bold border-gray-600 border-b-2 mb-4'>
                      {group.name}
                    </h2>
                    {groupMatches.length > 0 &&
                      groupMatches.map((m: any, i: number, arr: any[]) => {
                        const model = new SeasonWeekMatchModel(m);
                        const rank = arr.findIndex((item: any) => item.ht_score === m.ht_score) + 1;
                        return (
                          <LeaderboardSeasonRow
                            key={i}
                            rank={rank}
                            seasonWeekStatus={seasonWeekData.status}
                            matchStatus={m.status}
                            teamName={model.home_team?.[0]?.teamName}
                            schoolName={model.home_team?.[0]?.school?.name ?? 'CO-OP'}
                            organizationName={model.home_team?.[0]?.organizationName ?? 'CO-OP'}
                            matchId={model.id}
                            score={Number(m.ht_score)}
                            teamId={model.home_team?.[0]?.id}
                            hasScreenshots={model.hasScreenshots}
                            seasonStatus={seasonStatus}
                            teamUsers={m?.home_team?.[0]?.User.flatMap((user: any) =>
                              user.InGameName?.flatMap((inGameName: any) =>
                                inGameName.game_id === gameData?.id ? inGameName : [],
                              ),
                            )}
                            seasonProgramType={matchData?.seasonProgramType}
                          />
                        );
                      })}
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        ) : (
          matchData &&
          matchData.data.length > 0 && (
            <NormalMatches
              handleDragEnd={handleDragEnd}
              matchData={matchData}
              editMode={editMode}
              htSchool={htSchool}
              atSchool={atSchool}
              seasonStageGroups={seasonStageGroups}
            />
          )
        )
      ) : (
        <></>
      )}
    </div>
  );
}

export function LeaderboardSeasonRow({
  matchStatus,
  seasonWeekStatus,
  rank,
  teamName,
  schoolName,
  organizationName,
  matchId,
  score,
  teamId,
  hasScreenshots,
  seasonStatus,
  teamUsers,
  seasonProgramType,
}: {
  matchStatus: number | undefined;
  seasonWeekStatus: number | undefined;
  rank: number;
  teamName: string | undefined;
  schoolName: string | undefined;
  organizationName: string | undefined;
  matchId: number | undefined;
  score: number | undefined;
  teamId: number | undefined;
  hasScreenshots: boolean;
  seasonStatus: number | any;
  teamUsers: {
    game_id: number;
    user_id: number;
    in_game_name: string;
    discord_name: string;
  }[];
  seasonProgramType: number;
}) {
  const user = useSelector(selectUser);
  const [seeScreenshots, setSeeScrenshoots] = useState(false);

  return (
    <>
      <div
        className={`flex flex-1 gap-5 justify-between items-center border rounded-xl  p-2 ${
          user?.team?.find((team: any) => team?.id === teamId)
            ? 'border-red-600'
            : 'border-neutral-200'
        }`}
      >
        {seasonWeekStatus === SeasonWeekStatus.Published && rank > 0 && (
          <p className='font-bold text-xl border-r-2 pr-4'>{rank}</p>
        )}
        <div className='flex flex-col flex-1'>
          {user.roles === UserRoles.Admin ? (
            <span className='font-bold'>{`${
              teamName && teamName.length > 40 ? teamName.slice(0, 40) + '...' : teamName
            }: ${teamUsers.map((user) => user.in_game_name).join(', ')}`}</span>
          ) : (
            <span className='font-bold'>{teamName}</span>
          )}

          <div className='flex gap-4 justify-between flex-1'>
            <span>School: {schoolName}</span>
            <span>Organization: {organizationName}</span>
          </div>
        </div>

        <div className='flex gap-2 min-w-[200px]'>
          {user.roles === UserRoles.Admin ||
          user.roles === UserRoles.Ogma ||
          (user.roles === UserRoles.Student && user?.team?.find((t: any) => t.id === teamId)) ? (
            <div
              className='btn border rounded-xl flex justify-center items-center min-w-[200px]'
              onClick={() => setSeeScrenshoots(true)}
            >
              See Screenshots
            </div>
          ) : (
            <div className='flex-1 min-w-[200px]'></div>
          )}

          {user.roles === UserRoles.Admin ||
          ((seasonWeekStatus === SeasonWeekStatus.Closed ||
            seasonWeekStatus === SeasonWeekStatus.Open ||
            seasonWeekStatus === SeasonWeekStatus.Published) &&
            (user.roles === UserRoles.Student || user.roles === UserRoles.Ogma)) ? (
            <SeasonLeaderboardSubmitScore
              matchStatus={matchStatus!}
              matchId={matchId!}
              score={score!}
              teamId={teamId!}
              weekStatus={Number(seasonWeekStatus)}
              hasScreenshots={hasScreenshots}
              seasonStatus={seasonStatus}
              seasonProgramType={seasonProgramType}
            />
          ) : (
            <></>
          )}
          {seeScreenshots ? (
            <SeeAllScreenshoots
              matchId={matchId!}
              teamName='asd'
              close={() => setSeeScrenshoots(false)}
            />
          ) : (
            <></>
          )}

          <CanIView component='season.closeMatch'>
            <CloseSeasonWeekMatch
              matchId={matchId!}
              matchStatus={matchStatus!}
              seasonStatus={seasonStatus}
              seasonProgramType={seasonProgramType}
            />
          </CanIView>
          {seasonProgramType === SeasonProgramTypes.DroneRacing && (
            <Link className='flex btn bg-fen-blue items-center text-white' to={`./chat/${matchId}`}>
              Chat
            </Link>
          )}
        </div>
      </div>
    </>
  );
}

function CloseSeasonWeekMatch({
  matchId,
  matchStatus,
  seasonStatus,
  seasonProgramType,
}: {
  matchId: number;
  matchStatus: number;
  seasonStatus: number | any;
  seasonProgramType: number;
}) {
  const [closeMatch, { data, isLoading, isError, error, isSuccess }] =
    useCloseSeasonWeekMatchMutation();

  useEffect(() => {
    if (isSuccess)
      toast.success(ToastMessages.LeaderboardSingleMatchPublish, { position: 'top-right' });
  }, [isSuccess]);

  if (isError) return <p>Something went wrong...</p>;
  return (
    <>
      {matchStatus === MatchStatus.Completed || seasonStatus === SeasonProgressStatus.Completed ? (
        <button type='submit' className='rounded-xl w-[100px] bg-green-700 text-white p-2'>
          Published
        </button>
      ) : seasonProgramType !== SeasonProgramTypes.DroneRacing ? (
        <button
          type='submit'
          onClick={() => closeMatch(matchId)}
          disabled={isLoading}
          className='rounded-xl w-[100px] bg-gray-600 text-white p-2 hover:opacity-80'
        >
          {isLoading ? 'Publishing...' : 'Publish'}
        </button>
      ) : (
        <></>
      )}
    </>
  );
}

function SeasonLeaderboardSubmitScore({
  matchStatus,
  matchId,
  score,
  teamId,
  weekStatus,
  hasScreenshots,
  seasonStatus,
  seasonProgramType,
}: {
  matchStatus: number;
  matchId: number;
  score: number;
  teamId: number;
  weekStatus: number;
  hasScreenshots: boolean;
  seasonStatus: number | any;
  seasonProgramType: number;
}) {
  const user = useSelector(selectUser);
  const [submitScore, { data, isLoading, isError, error, isSuccess }] =
    useSubmitScoreSeasonWeekMutation();

  const {
    data: canSubmit,
    isLoading: canSubmitLoading,
    isError: canSubmitError,
  } = useCanSubmitScoreQuery(matchId);
  const SubmitScoreVSchema = yup.object().shape({
    score:
      seasonProgramType === SeasonProgramTypes.DroneRacing
        ? yup
            .string()
            .min(1)
            .required('Required!')
            .test('is-decimal', 'Enter time as: XXX.XXX', (value) =>
              /^\d{1,3}(\.\d{0,3})?$/.test(value || ''),
            )
        : yup.string().min(1).required('Required!'),
  });
  const isDisabled =
    (matchStatus === MatchStatus.Completed || seasonStatus === SeasonProgressStatus.Completed) &&
    user.roles !== UserRoles.Admin;
  const formInputs = {
    mainTitle: 'Season Information',
    name: 'score',
    label: `${seasonProgramType === SeasonProgramTypes.DroneRacing ? 'Time' : 'Score'}`,
    type: 'text',
    placeholder: `${
      seasonProgramType === SeasonProgramTypes.DroneRacing
        ? 'Enter time to 3 decimal places'
        : 'Enter score'
    }`,
    min: 0,
    isTrue: true,
    isDisabled,
  };

  function onSubmit(values: FormikValues) {
    submitScore({ matchId, body: { score: +values.score } });
    values.score = '';
  }

  useEffect(() => {
    if (isSuccess)
      toast.success(ToastMessages.LeaderboardMatchScoreSubmit, { position: 'top-right' });
  }, [isSuccess]);

  if (canSubmitLoading) return <p>Loading...</p>;

  return (
    <>
      <div className='flex gap-2 items-center'>
        {user.roles === UserRoles.Admin ? (
          <>
            {' '}
            <Formik
              validationSchema={SubmitScoreVSchema}
              initialValues={{
                score: score || hasScreenshots ? score : '',
              }}
              onSubmit={onSubmit}
              enableReinitialize
              // className="overflow-scroll"
            >
              <Form>
                <div className='flex gap-2'>
                  {/* <div> */}
                  <FormInput {...formInputs} />
                  {/* </div> */}
                  {user.roles === UserRoles.Admin ||
                  (((user.roles === UserRoles.Student &&
                    user.team?.filter((t: { id: number }) => t.id === teamId)?.length) ||
                    user.roles === UserRoles.Ogma) &&
                    MatchStatus.Completed !== matchStatus &&
                    seasonStatus !== SeasonProgressStatus.Completed) ? (
                    <SubmitButton
                      isLoading={isLoading}
                      disabled={isLoading}
                      extraClassname={matchStatus !== MatchStatus.Upcoming ? '!bg-green-700' : ''}
                      title={matchStatus === MatchStatus.Upcoming ? 'Submit' : 'Edit'}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Form>
            </Formik>
          </>
        ) : (
          <>
            <div
              className={`resetInput flex-1 text-center min-w-[100px] ${
                isDisabled ? 'disabled' : ''
              }`}
            >
              {Boolean(score || hasScreenshots || matchStatus !== MatchStatus.Upcoming)
                ? score
                : '-'}
            </div>
            {canSubmit &&
            SeasonWeekStatus.Open === weekStatus &&
            // ((teamId === user?.team?.[0]?.id && user.roles === UserRoles.Student) ||
            // user.roles === UserRoles.Ogma) &&
            matchStatus !== MatchStatus.Completed ? (
              // ((user.roles === UserRoles.Student &&
              //   user.team?.filter((t: { id: number }) => t.id === teamId)?.length) ||
              //   user.roles === UserRoles.Ogma) &&
              // matchStatus === MatchStatus.Upcoming &&
              // weekStatus === SeasonWeekStatus.Open ? (
              <Link className='btn red ' to={`./score/${matchId}`}>
                Submit
              </Link>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      {/* </Form> */}
      {/* </Formik> */}
    </>
  );
}

function NormalMatches({
  handleDragEnd,
  matchData,
  editMode,
  htSchool,
  atSchool,
  seasonStageGroups,
}: {
  handleDragEnd: (event: DragEndEvent) => void;
  matchData: any;
  editMode: boolean;
  htSchool?: string;
  atSchool?: string;
  seasonStageGroups?: any;
}) {
  const navigate = useNavigate();
  const ungroupedMatches = matchData?.data?.filter((match: any) => {
    const homeTeamGroup = seasonStageGroups.find((group: any) =>
      group.teams.some((team: any) => team?.id === match.home_team[0]?.id),
    );
    const awayTeamGroup = seasonStageGroups.find((group: any) =>
      group.teams.some((team: any) => team.id === match.away_team[0]?.id),
    );
    const teamIdsInAGroup = seasonStageGroups.flatMap((group: any) =>
      group.teams.map((team: any) => team.id),
    );

    return (
      (!homeTeamGroup && !awayTeamGroup) ||
      (homeTeamGroup && awayTeamGroup && homeTeamGroup !== awayTeamGroup)
    );
  });

  return (
    <DndContext onDragEnd={handleDragEnd}>
      {seasonStageGroups?.map((group: any, i: number) => {
        const groupMatches = matchData?.data?.filter((match: any) => {
          const teamIds = group.teams.map((team: any) => team.id);
          return (
            (teamIds.includes(match.home_team[0]?.id) &&
              (!match.away_team.length || teamIds.includes(match.away_team[0].id))) ||
            (match.home_team[0]?.isArchived && teamIds.includes(match.away_team[0].id)) ||
            (match.away_team[0]?.isArchived && teamIds.includes(match.home_team[0].id))
          );
        });

        return (
          <div key={i} className='w-full flex flex-col gap-4'>
            <h2 className='text-xl font-bold border-gray-600 border-b-2 mb-4'>{group.name}</h2>

            {groupMatches?.map((m: any, i: number) => (
              <div
                key={i}
                onClick={() => navigate(editMode ? '#' : `/match/${m.id}`)}
                className='w-full flex duration-100 container'
              >
                <ViewMatchDetails
                  htSchool={htSchool}
                  atSchool={atSchool}
                  {...m}
                  editMode={
                    editMode &&
                    (m.status === MatchStatus.Upcoming ||
                      m.status === MatchStatus.UpcomingRescheduledFor ||
                      m.status === MatchStatus.ByeMatch)
                  }
                  start_date={m.match_date}
                  size='xl'
                  home_team_length={m.home_team.length}
                  status={m.status}
                  away_team_length={m.away_team.length}
                  season_status={m.SeasonWeek.Season.status}
                  match_date={m.match_date}
                  isMatch={false}
                />
              </div>
            ))}
          </div>
        );
      })}

      {ungroupedMatches.length > 0 && (
        <div className='w-full flex flex-col gap-4'>
          <h2 className='text-xl font-bold border-gray-600 border-b-2 mb-4'>Ungrouped Matches</h2>
          {ungroupedMatches.map((m: any, i: number) => (
            <div
              key={i}
              onClick={() => navigate(editMode ? '#' : `/match/${m.id}`)}
              className='w-full flex container'
            >
              <ViewMatchDetails
                htSchool={htSchool}
                atSchool={atSchool}
                {...m}
                editMode={
                  editMode &&
                  (m.status === MatchStatus.Upcoming ||
                    m.status === MatchStatus.UpcomingRescheduledFor ||
                    m.status === MatchStatus.ByeMatch)
                }
                start_date={m.match_date}
                size='xl'
                home_team_length={m.home_team.length}
                status={m.status}
                away_team_length={m.away_team.length}
                season_status={m.SeasonWeek.Season.status}
                match_date={m.match_date}
                isMatch={false}
              />
            </div>
          ))}
        </div>
      )}
    </DndContext>
  );
}
