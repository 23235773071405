import * as yup from 'yup';
import { SchemaMessages } from '../../../../../utils/constants';
import { checkEndDate, checkStartDate } from '../../../../../utils/utils';

export const NewStageVSchema = () => {
  return yup.object().shape({
    name: yup.string().required('Required!'),
    num_groups: yup.number().when('stage_type_id', {
      is: (value: any) => value !== '3',
      then: (schema) => schema.required('Required!').min(1, 'Must be greater than 0'),
      otherwise: (schema) => schema.notRequired(),
    }),
    start_time: yup
      .mixed()
      .transform((value, originalValue) => originalValue)
      .test('test', SchemaMessages.SelectedDateMusBeFromToday, checkStartDate)
      .required('Required!'),
    end_time: yup
      .mixed()
      .transform((v, ov) => ov)
      .test('test', SchemaMessages.EndDate, (v, ctx) => checkEndDate(v, ctx, 'start_time')),
    stage_type_id: yup.string().required('Required!'),
    stage_team_selection_type_id: yup.number().required('Required!'),
  });
};
