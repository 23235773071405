import { SponsorsRow } from './../../components/sponsors-row';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { NameAndAvatar } from '../../components/name-and-avatar';
import TableContainer from '../../components/table-container/table-container';
import { TableHead } from '../../components/table-head';
import TableTd from '../../components/table-td/table-td';
import { useGetTournamentStandingQuery } from '../../slices/tournamentApiSlice';

export const sponsorArr = [
  (await import('../../assets/logo/bmw.png')).default,
  (await import('../../assets/logo/arch.png')).default,
  // import("../../assets/logo/google.png"),
  (await import('../../assets/logo/lenovo.webp')).default,
  (await import('../../assets/logo/samsung.webp')).default,
  (await import('../../assets/logo/sponsor1.png')).default,
  (await import('../../assets/logo/sponsor2.png')).default,
  (await import('../../assets/logo/sponsor3.png')).default,
  (await import('../../assets/logo/sponsor4.png')).default,
];

function TournamentStandings() {
  // const tableHead = ["position", "team", "match record", "game record"];
  const tableHead = ['position', 'team', 'wins', 'losses', 'points'];

  const standings = [
    {
      position: 1,
      team: (
        <NameAndAvatar
          image={
            'https://img.freepik.com/free-vector/detailed-esports-gaming-logo_79603-1792.jpg?w=2000'
          }
          name={'Team 1'}
        />
      ),
      matchRecord: '12W - 1L',
      gameRecord: '12W - 1L',
    },
    {
      position: 2,
      team: (
        <NameAndAvatar
          image={
            'https://img.freepik.com/free-vector/detailed-esports-gaming-logo_79603-1792.jpg?w=2000'
          }
          name={'Team 3'}
        />
      ),
      matchRecord: '12W - 1L',
      gameRecord: '12W - 1L',
    },
    {
      position: 3,
      team: (
        <NameAndAvatar
          image={
            'https://img.freepik.com/free-vector/detailed-esports-gaming-logo_79603-1792.jpg?w=2000'
          }
          name={'Team 2'}
        />
      ),
      matchRecord: '12W - 1L',
      gameRecord: '12W - 1L',
    },
  ];
  const param = useParams();
  const { data, isLoading, isError, isSuccess } = useGetTournamentStandingQuery(param.id);
  const navigate = useNavigate();
  if (isLoading) return <p>Loading...</p>;
  // sponsor__scale ---> on hover scales up
  //  sponsor__blink --> animation plays always on logos
  // sponsor__rotate --> on hover rotates
  // sponsor__container--border --> border animation on parent container
  return (
    <>
      <div className='flex flex-col  relative  bg-white w-full '>
        <SponsorsRow />{' '}
        <div className='listViewContainer p-10'>
          <TableContainer>
            <TableHead tableList={tableHead} />
            <tbody>
              {data.map((s: any, y: number) => (
                <tr
                  onClick={() => navigate(`/teams/view/${s.team.id}`)}
                  key={y}
                  className='hover:bg-black hover:bg-opacity-20 cursor-pointer'
                >
                  {tableHead.map((o, i) =>
                    o === 'position' ? (
                      <TableTd key={i}>{y + 1}</TableTd>
                    ) : o === 'team' ? (
                      <TableTd key={i}>
                        <NameAndAvatar image={s.team.image} name={s.team.School?.teamName} />{' '}
                      </TableTd>
                    ) : (
                      <TableTd key={i}>{s[o]}</TableTd>
                    ),
                  )}
                </tr>
              ))}
            </tbody>
          </TableContainer>
        </div>
      </div>
    </>
  );
}

export default TournamentStandings;
