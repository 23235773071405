import { useEffect, useState } from 'react';
import {
  useConnectInGameNameMutation,
  useGetAllGamesQuery,
  useGetStatsByGameQuery,
  useGetStudentInGameNameByIdQuery,
} from '../../../slices/gameApiSlice';
import { Error } from '../../../components/ui/error/Error';
import MatchScheduleView from '../../schedule/routes/match-schedule/components/match-schedule-view';
import { MatchScheduleModel } from '../../../model/match-schedule.model';
import Combobox from '../../../components/combobox/combobox';
import { useParams } from 'react-router';
import { Field, Form, Formik } from 'formik';
import { SubmitButton } from '../../../components/submit-button';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { UserRoles } from '../../../utils/constants';
import { CiEdit } from 'react-icons/ci';

const UserStats = () => {
  const param = useParams();
  const user = useSelector(selectUser);
  const { data, isLoading: gameIsLoading } = useGetAllGamesQuery({
    query: null,
  });

  const [selectedGame, setSelectedGame] = useState<number>();
  const [optionList, setOptionList] = useState<{ id: number | string; name: string }[]>([]);
  const [editIngameName, setEditIngameName] = useState(false);
  const [connectInGameName, { isLoading: inGameNameIsLoading, isSuccess, isError, error }] =
    useConnectInGameNameMutation();
  const { data: stats, isLoading } = useGetStatsByGameQuery(
    {
      gameId: optionList.filter((o) => o.id === selectedGame)?.[0]?.id,
      studentId: param.id,
    },
    { skip: !optionList.length },
  );
  const { data: inGameNames, isLoading: inGameNamesIsLoading } = useGetStudentInGameNameByIdQuery(
    param.id,
  );
  useEffect(() => {
    if (!data) return;
    setOptionList(data?.map((g: any) => ({ id: g.id, name: g.name })));
    setSelectedGame(data?.[0]?.id);
  }, [data]);

  const onSubmit = (values: any) => {
    connectInGameName({
      game_id: +selectedGame!,
      user_id: +param.id!,
      in_game_name: values.inGameName,
      discord_name: '',
    });
    setEditIngameName(false);
  };

  // console.log(game)

  if (isLoading || !optionList || gameIsLoading || inGameNamesIsLoading || !selectedGame)
    return <p>Loading...</p>;
  return (
    <div className='card flex flex-col justify-content-center'>
      <div className='relative p-5   flex-auto flex-col'>
        <h2 className='font-normal text-xl text-gray-800'>In Game Names</h2>
        <div className='flex flex-row flex-wrap my-4 gap-6 justify-center'>
          {inGameNames?.length ? (
            [...inGameNames]
              .sort((a: any, b: any) => a.game.name.localeCompare(b.game.name))
              .map((inGameName: any, i: number) => (
                <div key={i} className='flex flex-col mx-4'>
                  <h1 className='flex justify-center border-b-2 border-gray-300'>
                    {inGameName.game.name}
                  </h1>
                  <p className='flex justify-center'>{inGameName.in_game_name}</p>
                </div>
              ))
          ) : (
            <h1>No in game names.</h1>
          )}
        </div>
        <h2 className='font-normal text-xl text-gray-800'>Game</h2>
        <div className='flex w-[300px]'>
          <Combobox
            noFormik
            options={optionList}
            value={selectedGame}
            setFieldValue={(v) => {
              setSelectedGame(v);
              setEditIngameName(false);
            }}
            label='Select Game'
            name='Game'
          />
        </div>
      </div>
      {user.roles === UserRoles.Admin ? (
        <h1
          className='flex align-center justify-center text-xl text-bold hover:cursor-pointer'
          onClick={() => {
            setEditIngameName(!editIngameName);
          }}
        >
          In Game Name: {stats?.inGameName}
          {!editIngameName && <CiEdit className='ml-2' />}
        </h1>
      ) : (
        <h1 className='flex align-center justify-center text-xl text-bold'>
          In Game Name: {stats?.inGameName}
        </h1>
      )}

      {editIngameName ? (
        <>
          <Formik initialValues={{ inGameName: stats?.inGameName }} onSubmit={onSubmit}>
            <Form className='flex align-center justify-center '>
              <Field
                className='border-2 border-black'
                id='inGameName'
                name='inGameName'
                placeholder='In Game Name'
              />
              <SubmitButton
                isLoading={inGameNameIsLoading || isLoading}
                disabled={inGameNameIsLoading || isLoading}
              />
            </Form>
          </Formik>
        </>
      ) : (
        <></>
      )}
      <h2 className='font-normal p-5  text-xl text-gray-800'> Main Statistics</h2>

      <div className=' px-10'>
        <div className='flex p-5 px-20 justify-between gap-2 border rounded-lg border-gray-300 '>
          <div className='flex flex-col items-center'>
            <h2 className=' font-bold'>{stats?.match ? stats?.match?.length : 0} </h2>

            <h2 className='text-lg'>Matches</h2>
          </div>

          <div className='flex flex-col items-center'>
            <h2 className='font-bold'>{stats?.winPercentage ? stats?.winPercentage : 0} </h2>

            <h2 className='text-lg'>Win Rate %</h2>
          </div>

          <div className='flex flex-col items-center'>
            <h2 className=' font-bold'>{stats?.losePercentage ? stats?.losePercentage : 0}</h2>

            <h2 className='text-lg'>Loss Rate %</h2>
          </div>
        </div>
      </div>

      <h2 className='font-normal p-5  text-xl text-gray-800'> Match History</h2>

      {stats?.match?.length ? (
        stats?.match?.slice(0, 3).map((match: any, i: number) => (
          <div key={i} className='flex gap-3 flex-col p-2 px-10 '>
            <MatchScheduleView scheduleModel={new MatchScheduleModel(match)} />
          </div>
        ))
      ) : (
        <>
          <Error title='No matches for this game' hideButton />
        </>
      )}
    </div>
  );
};

export default UserStats;
