import { dateToLocal } from '../utils/dateFormatter';
import { wordWithFirstLetterToUpperCase } from '../utils/utils';

export class TeamModel {
  id;
  name;
  createdAt;
  f_createdAt;
  image;
  schoolImage;
  isArchived;
  game;
  school;
  numberOfStudents = 0;
  numberOfUsers = 0;
  status?: number;
  status_name?: string;
  updatedAt?;
  organization?: string;
  state?: string;
  isBenched?: number;
  numberOfActiveMembers = 0;
  activeSeasons?: { id: number; name: string }[];
  constructor(data: any) {
    if (data.hasOwnProperty('status')) {
      this.status = data.status;
    }
    if (data.hasOwnProperty('id')) this.id = data.id;

    if (data.hasOwnProperty('teamName')) this.name = wordWithFirstLetterToUpperCase(data.teamName);

    if (data.hasOwnProperty('created')) {
      this.createdAt = data.created;
      this.f_createdAt = dateToLocal(this.createdAt, 'MMMM D, YYYY');
    }

    if (data.hasOwnProperty('image')) this.image = data.image;

    if (data.hasOwnProperty('isArchived')) this.isArchived = data.isArchived;

    if (data.hasOwnProperty('Game') && data.Game && data.Game.hasOwnProperty('name'))
      this.game = data.Game.name;

    if (data.hasOwnProperty('School')) {
      this.school = data.School?.name ?? 'CO-OP';
      if (data.School?.hasOwnProperty('image')) this.schoolImage = data.School.image;
      if (data.School?.hasOwnProperty('organization')) {
        if (data.School.organization?.hasOwnProperty('name'))
          this.organization = data.School.organization.name;
        if (data.School.organization?.hasOwnProperty('state'))
          this.state = data.School.organization.state;
      }
    }
    if (data.hasOwnProperty('activeMembers')) {
      this.numberOfStudents += data.activeMembers.length;
      this.numberOfActiveMembers = data.activeMembers.length;
    }

    if (data.hasOwnProperty('subMembers')) this.numberOfStudents += data.subMembers.length;

    if (data.hasOwnProperty('updated_at'))
      this.updatedAt = dateToLocal(data?.updated_at, 'MMMM D, YYYY');

    if (data.hasOwnProperty('Users')) this.numberOfUsers += data.Users.length;

    if (data.hasOwnProperty('users')) this.numberOfUsers += data.users.length;

    if (data.hasOwnProperty('User')) this.numberOfUsers += data.User.length;

    if (data.hasOwnProperty('isBenched')) this.isBenched = data.isBenched;

    if (data.hasOwnProperty('season')) this.activeSeasons = data.season;
  }
  getOptionName() {
    return [this.name, this.school, this.organization, this.state].filter((o) => o).join(' / ');
  }
}
