import { Formik, useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../../../../components/outside-click';
import {
  useGetNewSRequiredDataQuery,
  useUpdateSeasonMutation,
  useViewSingleSeasonQuery,
} from '../../../../../../slices/seasonApiSlice';
import { EditSeasonVSchema } from './schema/edit-season-v-schema';
import ModalContent from '../../../../../../components/modal-content';
import { CustomError } from '../../../../../../utils/custom-types';
import { addCurrentTimeToDate, dateToLocal } from '../../../../../../utils/dateFormatter';
import { allStateCommunities, allStates } from '../../../../../../utils/constants';
interface FormValues {
  game_id: string;
  division_id: string;
  seasonTypeId: string;
  start_date: string;
  end_date: string;
  teams: string;
  subdivisions: string;
  state: string;
}

function EditSeason() {
  const [chosenTeam, setChosenTeam] = useState<object[]>([]);
  const [chosenSubDivision, setChosenSubDivision] = useState<object[]>([]);
  const [chosenGame, setChosenGame] = useState<number>();
  const [chosenCategoryDivision, setChosenCategoryDivision] = useState<number>();
  const [chosenSeasonType, setChosenSeasonType] = useState<any>();
  const [chosenState, setChosenState] = useState<string[]>([]);

  const [
    updateSeason,
    { data: updateData, isLoading: updateIsLoading, isSuccess: updateIsSuccess },
  ] = useUpdateSeasonMutation();

  const {
    data: reqData,
    isLoading: reqIsLoading,
    isSuccess: reqIsSuccess,
    isError: reqIsError,
  } = useGetNewSRequiredDataQuery('');
  const param = useParams();
  const {
    error,
    data: singleData,
    isLoading: singleIsLoading,
    isError: singleIsError,
  } = useViewSingleSeasonQuery(param.id);

  const UpdateChosenValues = ({ reqData }: { reqData: any }) => {
    const { values } = useFormikContext<FormValues>();

    useEffect(() => {
      if (!values || !reqData) return;
      setChosenGame(+values.game_id);
      setChosenState(chosenState);
      setChosenCategoryDivision(+values.division_id);
      setChosenSeasonType(
        reqData?.seasonTypes?.find((type: any) => type.id === +values.seasonTypeId),
      );
    }, [values, reqData]);

    return null;
  };

  useEffect(() => {
    if (!singleData) return;
    if (singleData?.teams) {
      console.log('singleData teams got called');
      setChosenTeam((s) => (singleData.teams ? [...s, ...singleData.teams] : []));
    }
    // console.log({ chosenSponsor });
    if (singleData?.subdivisions && !chosenSubDivision.length) {
      // setChosenCategoryDivision(singleData.division_id)
      singleData?.subdivisions.forEach((sub: any) =>
        setChosenSubDivision((s) =>
          singleData?.subdivisions ? [...s, { ...sub, division_id: singleData.division_id }] : [],
        ),
      );
    }
    setChosenState(singleData.state);
  }, [singleData]);

  const didMount = useRef(0);
  useEffect(() => {
    if (didMount.current >= 2) setChosenTeam([]);
    else didMount.current++;
  }, [chosenGame]);
  const setStateFieldValue = (state: string) => {
    if (chosenState.filter((s) => s === state).length > 0) {
      const newList = chosenState.filter((s) => s !== state);
      if (newList[0] === '') newList.shift();
      setChosenState(newList);
      return newList.join(', ');
    } else {
      setChosenState([...chosenState, state]);
      return [...chosenState, state].join(', ');
    }
  };
  const formInputs = [
    {
      mainTitle: 'Season Information',
      name: 'name',
      label: 'Season Name',
      type: 'text',
      placeholder: 'Enter season name',
      isTrue: true,
    },
    // {
    //   mainTitle: 'Season Information',
    //   name: 'seasonTypeId',
    //   label: 'Season Type',
    //   type: 'text',
    //   placeholder: 'Select the type of season',
    //   isTrue: true,
    //   isSelect: true,
    //   hidden: true,
    //   options: reqData?.seasonTypes ? reqData.seasonTypes : [],
    // },
    ...(singleData?.SeasonType?.leaderboard
      ? [
          {
            mainTitle: 'Season Information',
            name: 'orderRank',
            label: 'Rank',
            type: 'text',
            placeholder: 'Select the rank type of season',
            isTrue: true,
            isSelect: true,
            options: chosenSeasonType?.leaderboard
              ? [
                  { id: 0, name: 'DESC' },
                  { id: 1, name: 'ASC' },
                ]
              : [],
          },
        ]
      : []),
    // {
    //   mainTitle: 'Season Information',
    //   name: 'game_id',
    //   label: 'Game',
    //   type: 'text',
    //   placeholder: 'Game',
    //   isTrue: true,
    //   isSelect: true,
    //   options: reqData?.games ? reqData.games : [],
    // },
    // {
    //   mainTitle: 'Season Information',
    //   name: 'format_id',
    //   label: 'Match Format',
    //   type: 'text',
    //   hidden: singleData?.SeasonType?.leaderboard,
    //   placeholder: 'Select a match format',
    //   isTrue: true,
    //   isSelect: true,
    //   options:
    //     chosenGame && reqData.games.filter((g: any) => g.id === chosenGame)[0].MatchFormat
    //       ? reqData.games.filter((g: any) => g.id === chosenGame)[0].MatchFormat
    //       : [],
    // },
    {
      mainTitle: 'Season Information',
      name: 'division_id',
      label: 'Category Division',
      type: 'text',
      placeholder: 'Select season category',
      isTrue: true,
      isSelect: true,
      options: reqData?.divisions ? reqData.divisions : [],
    },
    {
      mainTitle: 'Season Information',
      name: 'state',
      label: 'State',
      type: 'multiSelect',
      placeholder: 'Enter state',
      isMultiSelect: true,
      options: allStates.concat(allStateCommunities) ?? [],
      setFieldValue: setStateFieldValue,
      selectedValues: chosenState.join(', '),
      multiple: true,
    },
    {
      mainTitle: 'Season Information',
      name: 'subdivisions',
      label: 'Subdivisions',
      type: 'text',
      placeholder: 'Enter subdivisions',
      isTrue: true,
      search: 'subdivision',
      divisionId: chosenCategoryDivision && chosenCategoryDivision,
      setChosenSubDivision,
      chosenSubDivision,
    },
    // {
    //   mainTitle: 'Season Information',
    //   name: 'teams',
    //   label: 'Teams',
    //   type: 'text',
    //   placeholder: 'Enter teams',
    //   isTrue: true,
    //   search: 'team',
    //   setChosenTeam,
    //   chosenGame,
    //   chosenTeam,
    // },
    {
      mainTitle: 'Season Information',
      name: 'start_date',
      label: 'Start Date',
      type: 'date',
      placeholder: 'Please select start date',
      isTrue: true,
    },
    {
      mainTitle: 'Season Information',
      name: 'end_date',
      label: 'End Date',
      type: 'date',
      placeholder: 'Please select end date',
      isTrue: true,
    },
    {
      mainTitle: 'Season Information',
      name: 'description',
      label: 'Information',
      type: 'textarea',
      placeholder: 'Enter information',
      isTrue: true,
    },
  ];
  function handleSubmit(values: any) {
    const data = { ...values };

    data.seasonTypeId = +data.seasonTypeId;
    // data.game_id = +data.game_id;
    // data.state = chosenState.join(', ');
    data.state = values.state;
    data.division_id = +data.division_id;
    data.orderRank = !data.orderRank ? 0 : Number(data.orderRank);
    // data.format_id = +data.format_id;
    // data.start_date = new Date(values.start_date).toISOString();
    // data.end_date = new Date(values.end_date).toISOString();
    data.start_date = addCurrentTimeToDate(values.start_date);
    data.end_date = addCurrentTimeToDate(values.end_date);

    data.teams = [];
    chosenTeam.forEach((t: any) => data.teams.push({ id: Number(t.id) }));

    data.subdivisions = [];
    chosenSubDivision.forEach(
      (sd: any) => sd.division_id === data.division_id && data.subdivisions.push({ id: sd.id }),
    );

    delete data.id;
    delete data.SeasonWeek;
    delete data.banner_image;
    delete data.image;
    delete data.isArchived;
    delete data.status;
    delete data.updated_at;
    delete data.created_at;
    delete data.standing;
    delete data.SeasonStage;

    updateSeason({ id: param.id, body: data })
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  }

  if (reqIsLoading || singleIsLoading) return <p>Loading...</p>;
  if (singleIsError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (updateIsSuccess) return <Navigate to='../' />;
  const editSeasonSchema = EditSeasonVSchema(
    singleData?.start_date,
    singleData?.SeasonType?.leaderboard,
  );
  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={editSeasonSchema}
        initialValues={{
          ...singleData,
          start_date: dateToLocal(singleData.start_date).split('T')[0],
          end_date: dateToLocal(singleData.end_date).split('T')[0],
          teams: '',
          subdivisions: '',
          state: singleData.state.join(', '),
        }}
        onSubmit={handleSubmit}
      >
        {({ values, errors }) => {
          // FIXME: React Hook "useEffect" cannot be called inside a callback. React Hooks must be called in a React function component or a custom React Hook function.
          // useEffect(() => {
          //   if (!values || !reqData) return;
          //   setChosenGame(+values.game_id);
          //   setChosenState(chosenState);
          //   setChosenCategoryDivision(+values.division_id);
          //   setChosenSeasonType(
          //     reqData?.seasonTypes?.find((type: any) => type.id === +values.seasonTypeId),
          //   );
          // }, [values, reqData]);
          return (
            <>
              <UpdateChosenValues reqData={reqData} />
              <ModalContent
                errors={Object.keys(errors).length}
                formInputs={formInputs}
                isLoading={updateIsLoading}
                name={`Edit Season - ${singleData.name}`}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default EditSeason;
