import { TeamStatsTable } from './components/team-stats-table';
import { TeamStatHeader } from './components/team-stat-header';
import { SponsorRow } from '../../../../../components/sponsor-row/sponsor-row';
import { SponsorPlacements } from '../../../../../utils/constants';
import { useGetTeamByIdQuery } from '../../../../../slices/teamsApiSlice';
import { useNavigate, useParams } from 'react-router';
import Combobox from '../../../../../components/combobox/combobox';
import { useState } from 'react';
import TeamMatchHistory from './components/team-match-history';

function TeamViewStats() {
  const param = useParams();
  const navigate = useNavigate();
  const [selectedSeason, setSelectedSeason] = useState<string>();
  const {
    data: teamData,
    isLoading: teamIsLoading,
    isError: teamIsError,
    isSuccess: teamIsSuccess,
  } = useGetTeamByIdQuery({
    id: param.id,
    query: 'match',
  });

  if (teamIsLoading) return <p>Loading...</p>;
  return (
    <div className='flex flex-col p-4 gap-5'>
      <div>
        <SponsorRow name={SponsorPlacements.TeamsStats} />
      </div>
      <TeamStatHeader
        gameName={teamData?.Game?.name}
        gameImage={teamData?.Game?.image}
        teamData={teamData}
      />
      <div className='w-fit'>
        <h1 className='font-bold text-lg text-center border-b-2 border-black mb-4'>
          Active Seasons
        </h1>
        {/* <h3>Completed Seasons</h3> */}
        {teamData?.season &&
          [...teamData.season]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((season) => {
              return (
                <div
                  key={season.id}
                  className='hover:text-black hover:bg-opacity-20 hover:font-bold hover:underline text-center'
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`../seasons/view/${season.id}`);
                  }}
                >
                  {season.name}
                </div>
              );
            })}
      </div>
      <div>
        <h1 className='font-bold text-lg text-center border-b-2 border-black mb-4'>
          Match History
        </h1>
        <h2 className='font-normal text-gray-800'>Select Season</h2>
        <div className='flex w-[300px]'>
          <Combobox
            noFormik
            options={teamData.season}
            value={selectedSeason}
            setFieldValue={(v) => {
              setSelectedSeason(v);
            }}
            label='Select Season'
            name='Season'
          />
        </div>
        <div>
          <TeamMatchHistory teamId={teamData.id} seasonId={selectedSeason} />
        </div>
      </div>
      {/* <TeamStatsTable />
      <TeamStatsTable /> */}
    </div>
  );
}
//

export default TeamViewStats;
