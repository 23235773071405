import { useEffect, useState } from 'react';
import { UserRoles } from '../../../utils/constants';
import { dateToLocal } from '../../../utils/dateFormatter';
import { MatchTeamAway } from './match-team-away';
import { MatchTeamHome } from './match-team-home';
import { useLocation, useParams } from 'react-router';
import { FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ViewSchoolOrgMatch from './view-school-org-match';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';

enum MatchStatus {
  Upcoming = 0,
  Completed = 1,
  InProgress = 2,
  Overdue = 3,
  Cancelled = 4,
  Forfeit = 5,
  DoubleForfeit = 6,
  ByeMatch = 7,
  RescheduleRequested = 8,
  Benched = 9,
  UpcomingRescheduledFor = 10,
}

export function ViewMatchDetails({
  id = 0,
  home_team = [
    {
      id: 1,
      image: '',
      teamName: '',
      isBenched: 0,
      organizations: [],
      School: {
        id: 1,
        name: '',
        organization: { id: 1, name: '', ogmas: [], user: { id: '' } },
        image: '',
      },
      activeMembers: '',
      subMembers: '',
    },
  ],
  away_team = [
    {
      id: 1,
      image: '',
      teamName: '',
      isBenched: 0,
      organizations: [],
      School: {
        id: 1,
        name: '',
        organization: { id: 1, name: '', ogmas: [], user: { id: '' } },
        image: '',
      },
      activeMembers: '',
      subMembers: '',
    },
  ],
  match_date = '',
  ht_score = '',
  at_score = '',
  htSchool = '',
  atSchool = '',
  bgColor = '',
  isTournament = false,
  status = MatchStatus.Upcoming,
  isStreaming = 0,
  size = 'md',
  editMode = false,
  away_team_length = 0,
  home_team_length = 0,
  season_status = 2,
  isMatch = false,
  Game = { name: '' },
  game_id = '',
}) {
  const [shouldShowLabel, setShouldShowLabel] = useState(false);
  const location = useLocation();
  const currentUrl = location.pathname;
  const param = useParams();
  useEffect(() => {
    const currentRoute = `/seasons/view/${param.id}/`;
    const secondCurrentRoute = `/seasons/view/${param.id}`;
    const isOnCorrectRoute = currentUrl === currentRoute || currentUrl === secondCurrentRoute;
    console.log('currentRoute', currentRoute);
    console.log('currentUrl', currentUrl);
    console.log(isOnCorrectRoute);

    if (isOnCorrectRoute) {
      setShouldShowLabel(true);
    } else {
      setShouldShowLabel(false);
    }
  }, [currentUrl, param.id]);

  const matchStatusColors = {
    [MatchStatus.Upcoming]: 'black',
    [MatchStatus.Completed]: 'text-green-600',
    [MatchStatus.InProgress]: 'text-yellow-600',
    [MatchStatus.Forfeit]: 'text-red-600',
    [MatchStatus.DoubleForfeit]: 'text-red-600',
    [MatchStatus.Cancelled]: 'text-gray-600',
    [MatchStatus.Overdue]: 'text-red-600',
    [MatchStatus.ByeMatch]: 'text-blue-600',
    [MatchStatus.RescheduleRequested]: 'text-orange-500',
    [MatchStatus.Benched]: 'text-purple-600',
    [MatchStatus.UpcomingRescheduledFor]: 'black',
  };

  const matchStatusNames = {
    [MatchStatus.Upcoming]: 'UPCOMING',
    [MatchStatus.Completed]: 'COMPLETED',
    [MatchStatus.InProgress]: 'IN PROGRESS',
    [MatchStatus.Forfeit]: 'FORFEITED',
    [MatchStatus.DoubleForfeit]: 'DOUBLE FORFEITED',
    [MatchStatus.Cancelled]: 'CANCELLED',
    [MatchStatus.Overdue]: 'OVERDUE!',
    [MatchStatus.ByeMatch]: 'BYE',
    [MatchStatus.RescheduleRequested]: 'RESCHEDULE REQUESTED!',
    [MatchStatus.Benched]: 'BENCHED',
    [MatchStatus.UpcomingRescheduledFor]: 'UPCOMING (Rescheduled for)',
  };

  let currentDate = new Date(match_date);
  currentDate.setDate(currentDate.getDate() + 1);

  const hasTeams = home_team.length > 0 && away_team.length > 0;
  const customStyles = !hasTeams ? { backgroundColor: '', borderRadius: '16px' } : {};

  const minWidth =
    home_team?.[0]?.teamName.length > away_team?.[0]?.teamName.length
      ? home_team?.[0]?.teamName.length
      : away_team?.[0]?.teamName.length;

  const [showHover, setShowHover] = useState<boolean>(false);
  let timeOutId: ReturnType<typeof setTimeout> = setTimeout(() => {});

  const handleMouseEnter = () => {
    timeOutId = setTimeout(() => setShowHover(true), 500);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeOutId);
    setShowHover(false);
  };

  const user = useSelector(selectUser);
  const [isTeamBenched, setIsTeamBenched] = useState<boolean>(false);

  const setInitialState = () => {
    if (user?.roles === UserRoles.Admin) return true;
    else return false;
  };

  const [part, setPart] = useState<any>({
    home: false,
    away: false,
  });

  useEffect(() => {
    if (user?.roles === UserRoles.Ogma) {
      const homeOgmas = [
        home_team?.[0]?.organizations?.map((org: any) => org.user?.id),
        home_team?.[0]?.School?.organization?.user?.id,
        ...(home_team?.[0]?.organizations?.map((org: any) =>
          org.ogmas?.map((o: { id: number }) => o.id),
        ) ?? []),
        ...(home_team?.[0]?.School?.organization?.ogmas?.map((o: { id: number }) => o.id) ?? []),
      ].flat();

      const awayOgmas = [
        away_team?.[0]?.organizations?.map((org: any) => org.user?.id),
        away_team?.[0]?.School?.organization?.user?.id,
        ...(away_team?.[0]?.organizations?.map((org: any) =>
          org.ogmas?.map((o: { id: number }) => o.id),
        ) ?? []),
        ...(away_team?.[0]?.School?.organization?.ogmas?.map((o: { id: number }) => o.id) ?? []),
      ].flat();

      if (homeOgmas?.includes(user?.id) && home_team?.[0]?.isBenched)
        setPart((prevState: any) => ({ ...prevState, home: true }));
      else setPart((prevState: any) => ({ ...prevState, home: false }));

      if (awayOgmas?.includes(user?.id) && away_team?.[0]?.isBenched)
        setPart((prevState: any) => ({ ...prevState, away: true }));
      else setPart((prevState: any) => ({ ...prevState, away: false }));
    }

    if (user?.roles === UserRoles.Student) {
      const homePart =
        home_team?.[0]?.activeMembers?.includes(user?.id) ||
        home_team?.[0]?.subMembers?.includes(user?.id);

      const awayPart =
        away_team?.[0]?.activeMembers?.includes(user?.id) ||
        away_team?.[0]?.subMembers?.includes(user?.id);

      if (homePart && home_team?.[0]?.isBenched)
        setPart((prevState: any) => ({ ...prevState, home: true }));
      else setPart((prevState: any) => ({ ...prevState, home: false }));

      if (awayPart && away_team?.[0]?.isBenched)
        setPart((prevState: any) => ({ ...prevState, away: true }));
      else setPart((prevState: any) => ({ ...prevState, away: false }));
    }
  }, [home_team, away_team]);

  return (
    <div
      style={customStyles}
      className={`flex relative p-1 bg-white w-full items-center ${bgColor} 
        `}
    >
      <div
        className={`flex container m-auto gap-2 justify-center  items-center flex-row flex-wrap`}
      >
        <div className='flex min-w-[300px] flex-1 flex-col relative gap-2 group'>
          <h3 className='self-center font-bold text-xl'>Home</h3>
          <MatchTeamHome
            matchId={id}
            minWidth={minWidth}
            id={home_team?.[0]?.id}
            image={home_team?.[0]?.School?.image}
            team={home_team?.[0]?.teamName}
            win={ht_score > at_score}
            result={ht_score}
            school={htSchool}
            size={size}
            editMode={editMode}
            shouldShowLabel={shouldShowLabel}
            home_team_length={home_team?.length ?? 0}
            season_status={season_status}
            status={status}
            match_date={match_date}
            isMatch={isMatch}
            teamData={home_team?.[0]}
            onMouseEnter={handleMouseEnter}
          />

          {showHover && !editMode ? (
            <div className='hidden w-full backdrop-blur-[3px] rounded-lg bg-[#4A4A4ACC] group-hover:block absolute top-0 h-[230px] overflow-y-auto z-50 opacity-85'>
              <ViewSchoolOrgMatch
                teamId={home_team?.[0]?.id}
                teamName={home_team?.[0]?.teamName}
                teamGameName={Game?.name}
                schoolId={home_team?.[0]?.School?.id}
                schoolName={home_team?.[0]?.School?.name}
                orgId={home_team?.[0]?.School?.organization?.id}
                orgName={home_team?.[0]?.School?.organization?.name}
                image={home_team?.[0]?.School?.image}
                coopTeamOrgs={home_team?.[0]?.organizations}
                coopOgmas={home_team?.[0]?.organizations}
                schoolOgmas={home_team?.[0]?.School?.organization}
                gameId={game_id}
                canViewBenchStatus={
                  (user?.roles === UserRoles?.Admin && home_team?.[0]?.isBenched) || part?.home
                }
                isBenched={home_team?.[0]?.isBenched ? true : false}
                setShowHover={setShowHover}
                showHover={showHover}
              />
            </div>
          ) : null}
        </div>

        <span className='text-center w-[300px]'>
          <div className='span text-[10px] leading-tight flex flex-col h-fit top-0 left-0 right-0 m-auto items-center'>
            <div className='hidden md:flex flex-col justify-center h-full'>
              <span
                className={`text-center ${
                  user?.roles === UserRoles.Admin &&
                  (home_team?.[0]?.isBenched || away_team?.[0]?.isBenched) &&
                  status === MatchStatus.ByeMatch
                    ? 'text-purple-600'
                    : (user?.roles === UserRoles.Ogma || user?.roles === UserRoles.Student) &&
                      (part?.home || part?.away) &&
                      status === MatchStatus.ByeMatch
                    ? 'text-purple-600'
                    : matchStatusColors[status] || ''
                } }`}
              >
                <div className='span text-[10px] leading-tight flex flex-col h-fit top-0 left-0 right-0 m-auto items-center'>
                  <span
                    className={`font-bold text-sm ${
                      user?.roles === UserRoles.Admin &&
                      (home_team?.[0]?.isBenched || away_team?.[0]?.isBenched) &&
                      status === MatchStatus.ByeMatch
                        ? ''
                        : (user?.roles === UserRoles.Ogma || user?.roles === UserRoles.Student) &&
                          (part?.home || part?.away) &&
                          status === MatchStatus.ByeMatch
                        ? ''
                        : matchStatusColors[status] || ''
                    }`}
                  >
                    {currentDate < new Date(Date.now()) &&
                    matchStatusNames[status] === 'UPCOMING' ? (
                      <p className='text-sm font-bold text-red-800'>OVERDUE!</p>
                    ) : user?.roles === UserRoles?.Admin &&
                      (home_team?.[0]?.isBenched || away_team?.[0]?.isBenched) &&
                      status === MatchStatus.ByeMatch ? (
                      <span className={`text-purple-600 font-bold font-[Inter] text-sm`}>
                        BENCHED
                      </span>
                    ) : (user?.roles === UserRoles.Ogma || user?.roles === UserRoles.Student) &&
                      status === MatchStatus.ByeMatch &&
                      (part?.home || part?.away) ? (
                      <span className={`text-purple-600 font-bold font-[Inter] text-sm`}>
                        BENCHED
                      </span>
                    ) : (
                      matchStatusNames[status]
                    )}
                  </span>
                  {user?.roles === UserRoles?.Admin &&
                  (home_team?.[0]?.isBenched || away_team?.[0]?.isBenched) &&
                  status !== MatchStatus.ByeMatch ? (
                    <span className={`text-[#B50303] font-[600] font-[Inter] text-[12px]`}>
                      BENCHED
                    </span>
                  ) : (user?.roles === UserRoles.Ogma || user?.roles === UserRoles.Student) &&
                    part?.home &&
                    status !== MatchStatus.ByeMatch ? (
                    <span className={`text-[#B50303] font-[600] font-[Inter] text-[12px]`}>
                      BENCHED
                    </span>
                  ) : (user?.roles === UserRoles.Ogma || user?.roles === UserRoles.Student) &&
                    part?.away &&
                    status !== MatchStatus.ByeMatch ? (
                    <span className={`text-[#B50303] font-[600] font-[Inter] text-[12px]`}>
                      BENCHED
                    </span>
                  ) : null}
                  <p className='font-bold text-sm'>
                    {dateToLocal(new Date(match_date), 'llll zz')}
                  </p>
                </div>
                VS
              </span>
            </div>
          </div>
        </span>

        <div className='flex min-w-[300px] flex-1 flex-col gap-2 relative group'>
          <h3 className='self-center font-bold text-xl'>Away</h3>
          <MatchTeamAway
            matchId={id}
            minWidth={minWidth}
            id={away_team?.[0]?.id}
            image={away_team?.[0]?.School?.image}
            win={at_score > ht_score}
            result={at_score}
            school={atSchool}
            team={away_team?.[0]?.teamName}
            size={size}
            editMode={editMode}
            shouldShowLabel={shouldShowLabel}
            away_team_length={away_team?.length ?? 0}
            season_status={season_status}
            status={status}
            match_date={match_date}
            isMatch={isMatch}
            teamData={away_team?.[0]}
            onMouseEnter={handleMouseEnter}
          />
          {showHover && !editMode ? (
            <div className='hidden w-full backdrop-blur-[3px] rounded-lg bg-[#4A4A4ACC] group-hover:block absolute top-0 h-[230px] overflow-y-auto z-50 opacity-85'>
              <ViewSchoolOrgMatch
                teamId={away_team?.[0]?.id}
                teamName={away_team?.[0]?.teamName}
                teamGameName={Game?.name}
                schoolId={away_team?.[0]?.School?.id}
                schoolName={away_team?.[0]?.School?.name}
                orgId={away_team?.[0]?.School?.organization?.id}
                orgName={away_team?.[0]?.School?.organization?.name}
                image={away_team?.[0]?.School?.image}
                coopTeamOrgs={away_team?.[0]?.organizations}
                coopOgmas={away_team?.[0]?.organizations}
                schoolOgmas={away_team?.[0]?.School?.organization}
                gameId={game_id}
                canViewBenchStatus={
                  (user?.roles === UserRoles?.Admin && away_team?.[0]?.isBenched) || part?.away
                }
                isBenched={away_team?.[0]?.isBenched ? true : false}
                setShowHover={setShowHover}
                showHover={showHover}
              />
            </div>
          ) : null}
        </div>
      </div>
      {editMode && [MatchStatus.Upcoming, MatchStatus.ByeMatch].includes(status) ? (
        <div className='absolute right-0 xl:left-0 xl:-top-2 xl:bottom-auto m-auto z-[0] flex items-center justify-center cursor-pointer gap-2'>
          <Link className='hover:scale-110 duration-300 text-red-500' to={`./delete-match/${id}`}>
            <FaTrash className='' />
          </Link>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
