import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { NameAndAvatar } from '../../../../../../components/name-and-avatar';
import { useViewSingleSeasonQuery } from '../../../../../../slices/seasonApiSlice';
import { useTournamentsTeamsQuery } from '../../../../../../slices/tournamentApiSlice';
import Pagination from '../../../../../../components/pagination';
import { TeamModel } from '../../../../../../model/team.model';

export function CheckboxListTeamsSeason({
  chosenTeam,
  setChosenTeam,
}: {
  chosenTeam: any;
  setChosenTeam: Function;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const [selectedGame, setSelectedGame] = useState('');
  const { data: tData } = useViewSingleSeasonQuery(params.id);
  const { data, isLoading, isSuccess, isError } = useTournamentsTeamsQuery({
    id: tData.game_id,
    page: searchParams.get('page') ? searchParams.get('page') : 1,
    game: searchParams.get('game') ? searchParams.get('game') : '',
    seasonState: tData?.state ? tData.state : '',
  });
  const [allTeams, setAllTeams] = useState<string[]>([]);
  useEffect(() => {
    setSearchParams({ game: selectedGame });
  }, [selectedGame]);
  type teamOb = {
    id: number;
    teamName: string;
    image?: string;
  };
  useEffect(() => {
    if (!data?.data?.length) return;
    // const arr = [...chosenTeam, ...data.data];
    const arr: any = [];

    chosenTeam.forEach((t: any) => {
      const model = new TeamModel(t);
      const { id, image } = model;
      arr.push(JSON.stringify({ id, teamName: model.getOptionName(), image }));
    });
    data.data.forEach((t: any) => {
      const model = new TeamModel(t);
      const { id, image } = model;
      if (arr.filter((team: any) => JSON.parse(team).id === id).length === 0)
        arr.push(JSON.stringify({ id, teamName: model.getOptionName(), image }));
    });

    const set = new Set<string>(arr);
    // setAllTeams([...set]);
    setAllTeams(arr);
  }, [data?.data]);
  const inputProps = {
    mainTitle: 'Tournament Information',
    name: 'format_id',
    label: 'Match Format',
    type: 'text',
    placeholder: 'Match format',
    isTrue: true,
    isSelect: true,
    // options: tData ? tData.Game : [],
    options: [],
  };

  if (isLoading) return <p>Loading...</p>;
  return (
    // <div className='flex flex-col'>
    <div className='flex pt-5 flex-col gap-5 justify-between flex-1'>
      <div className='flex flex-col gap-5'>
        <h2 className='font-bold'>Teams</h2>
        <div className='flex gap-5 items-center'>
          <input
            type='checkbox'
            checked={allTeams?.every((i: any) =>
              chosenTeam.find((t: any) => t.id === JSON.parse(i).id),
            )}
            onClick={() =>
              allTeams?.every((i: any) => chosenTeam.find((t: any) => t.id === JSON.parse(i).id))
                ? setChosenTeam([
                    ...chosenTeam.filter(
                      (t: any) => !allTeams?.find((d: any) => JSON.parse(d).id === t.id),
                    ),
                  ])
                : setChosenTeam([
                    ...chosenTeam.filter(
                      (t: any) => !allTeams?.find((d: any) => JSON.parse(d).id === t.id),
                    ),
                    ...data.data.map((t: any) => ({
                      id: t.id,
                      image: t.School?.image,
                      teamName: new TeamModel(t).getOptionName(),
                    })),
                  ])
            }
            className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
          />
          Add All Teams
        </div>
        {allTeams?.map((a: any, i: number) => {
          const t: any = JSON.parse(a);
          const model = new TeamModel(t);
          return (
            <div
              key={i}
              className='flex gap-5 items-center truncate'
              onClick={() =>
                chosenTeam.find((i: any) => i.id === t.id)
                  ? setChosenTeam((s: any) => s.filter((a: any) => a.id !== t.id))
                  : setChosenTeam((s: any) => [...s, t])
              }
            >
              <input
                type='checkbox'
                className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                checked={Boolean(chosenTeam.find((i: any) => i.id === t.id))}
              />
              <NameAndAvatar image={t.School?.image} name={t.teamName} />
            </div>
          );
        })}
      </div>
      <Pagination length={data?.data?.length} {...data?.meta} />
    </div>
    // </div>
  );
}
