import { useEffect } from 'react';
import { useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { OutsideClick } from '../../../../../../components/outside-click';
import {
  useAddTeamsToSeasonWeekMutation,
  useViewSingleSeasonQuery,
} from '../../../../../../slices/seasonApiSlice';
import ModalHeader from '../../../../../../components/modal-header';
import ModalFooter from '../../../../../../components/modal-footer';
import { CustomError } from '../../../../../../utils/custom-types';
import { TeamModel } from '../../../../../../model/team.model';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../../slices/auth';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { UserRoles } from '../../../../../../utils/constants';
import { toast } from 'react-toastify';

function JoinWeek() {
  const param = useParams();
  const [searchParams] = useSearchParams();
  const user = useSelector(selectUser);
  const [teamsToJoin, setTeamsToJoin] = useState<{ id: number; teamName: string }[]>([]);

  const [addTeamsToSeason, { isLoading, isSuccess }] = useAddTeamsToSeasonWeekMutation();
  const {
    error,
    data: seasonData,
    isLoading: seasonIsLoading,
    isError: seasonIsError,
    isSuccess: seasonIsSuccess,
  } = useViewSingleSeasonQuery(param.id, { skip: !param?.id });

  useEffect(() => {
    const existingTeams = seasonData?.SeasonStage?.flatMap((stage: any) => stage?.SeasonWeek)
      ?.find((week: any) => week?.id === Number(searchParams.get('week')))
      ?.teams?.map((team: any) => ({ id: team?.id, teamName: team.teamName }));
    setTeamsToJoin(existingTeams ?? []);
  }, [seasonData, searchParams]);

  const onSubmit = () => {
    addTeamsToSeason({
      weekId: searchParams.get('week') as string,
      body: { teams: teamsToJoin.map((team) => ({ id: team.id, name: team.teamName })) },
    });
  };
  useEffect(() => {
    if (isSuccess)
      toast.success('You succesfully joined the week, good luck!', { position: 'top-right' });
  }, [isSuccess]);
  if (seasonIsError || (seasonIsSuccess && !seasonData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (seasonIsLoading) return <p>Loading...</p>;
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <div className='fixed top-0 left-0 bottom-0 right-0 m-auto z-10 w-auto  max-w-2xl h-fit max-h-[90vh]'>
        <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full'>
          <ModalHeader name={'Add Teams'} />
          <div className='relative px-3 flex-auto overflow-auto'>
            {seasonData?.teams
              ?.filter((team: any) => {
                const isAdmin = Boolean(user.roles === UserRoles.Admin);
                const isTeamCaptain = Boolean(team.captain === user.id);
                const isOgmaMyTeam = Boolean(
                  user?.ogma_org?.filter((t: any) => t?.name === team.School?.organization?.name)
                    ?.length ||
                    user?.ogma_org?.filter((t: any) =>
                      team.organizations?.map((org: any) => org.id).includes(t.id),
                    )?.length,
                );
                return isTeamCaptain || isOgmaMyTeam || isAdmin;
              })
              .map((t: any) => (
                <div key={t.id} className='my-4'>
                  <span
                    onClick={() => {
                      teamsToJoin.flatMap((team) => team.id).includes(t.id)
                        ? setTeamsToJoin((s: any) => s.filter((team: any) => team.id !== t.id))
                        : setTeamsToJoin((s: any) => [...s, { id: t.id, teamName: t.teamName }]);
                    }}
                    className={`group flex justify-center items-center border-2 rounded-lg gap-2 p-2 cursor-pointer w-fit ${
                      teamsToJoin.flatMap((team) => team.id).includes(t.id) ? 'bg-gray-300' : ''
                    }`}
                  >
                    <p>{new TeamModel(t).getOptionName()}</p>
                    <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
                  </span>
                </div>
              ))}
          </div>
          <ModalFooter onSubmit={onSubmit} isLoading={isLoading} />
        </div>
      </div>
    </>
  );
}

export default JoinWeek;
