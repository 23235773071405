import { Formik, FormikValues } from 'formik';
import { Navigate } from 'react-router-dom';
import { OutsideClick } from '../../../components/outside-click';
import { allStateCommunities, allStates } from '../../../utils/constants';
import {
  useCreateNewOrganizationMutation,
  useNewOrganizationMutation,
} from '../../../slices/organizationApiSlice';
import { useGetSchoolsWithoutOrgQuery } from '../../../slices/schoolsApiSlice';
import { useState } from 'react';
import { NewOrganizationSchema } from './yup-schema/new-organization.schema';
import ModalContent from '../../../components/modal-content';

const NewOrganizationModal = () => {
  const [chosenCoaches, setChosenCoaches] = useState<{ id: number; firstName: string }[]>([]);
  const [createNewOrganization, { isError, isLoading, isSuccess }] =
    useCreateNewOrganizationMutation();
  const { data, isLoading: schoolIsLoading } = useGetSchoolsWithoutOrgQuery('');

  const formInputs = [
    {
      mainTitle: 'Organization Information',
      name: 'name',
      label: 'Organization Name',
      placeholder: 'Enter organization name',
      // isTrue: true,
    },
    {
      mainTitle: 'Organization Information',
      name: 'street_address',
      label: 'Street Address',
      placeholder: 'Enter street address',
    },
    {
      mainTitle: 'Organization Information',
      name: 'city',
      label: 'City',
      placeholder: 'Enter city',
    },
    {
      mainTitle: 'Organization Information',
      name: 'state',
      label: 'State',
      placeholder: 'Enter state',
      isSelect: true,
      options: allStates.concat(allStateCommunities) ?? [],
    },
    {
      mainTitle: 'Organization Information',
      name: 'zip_code',
      label: 'Zip Code',
      type: 'number',
      placeholder: 'Enter zip code',
    },
    {
      mainTitle: 'Organization Information',
      name: 'tags',
      label: 'Tags',
      placeholder: 'Enter tags',
      // isTrue: true,
    },
    {
      mainTitle: 'Organization Information',
      name: 'email',
      label: 'Email Address',
      placeholder: 'Enter email address',
    },
    {
      mainTitle: 'Organization Information',
      name: 'phone_number',
      label: 'Phone Number',
      placeholder: 'Enter phone number',
    },
    // {
    //   mainTitle: 'Organization Information',
    //   name: 'schools',
    //   label: 'Schools',
    //   placeholder: 'schools',
    //   isTrue: true,
    //   isSelect: true,
    //   options: data?.school ? data?.school : [],
    // },
    {
      mainTitle: 'Organization Information',
      name: 'status',
      label: 'Status',
      placeholder: 'status',
      isSelect: true,
      options: [
        { id: 0, name: 'Disable' },
        { id: 1, name: 'Active' },
      ],
    },
    // {
    //   mainTitle: 'Organization Information',
    //   name: 'coach',
    //   label: 'Coach',
    //   placeholder: 'Coach',
    //   // isSelect: true,
    //   // options: data?.coach ? data?.coach : [],
    //   search: 'coach',
    //   setChosenCoaches,
    //   chosenCoaches,
    //   isTrue: true,
    // },
  ];
  const [newOrganization, { isLoading: newLoading, isError: newError, isSuccess: newSuccess }] =
    useNewOrganizationMutation();
  const onSubmit = (values: FormikValues) => {
    let data: any = {};
    data.name = values.name;
    data.street_address = values.street_address;
    data.city = values.city;
    data.zip_code = Number(values.zip_code);
    data.email = values.email;
    data.tags = values.tags;
    data.status = Number(values.status);
    data.state = values.state;
    data.phone_number = values.phone_number;

    if (chosenCoaches) {
      data.coach = [];
      chosenCoaches.forEach((t) => data?.coach?.push({ id: +t.id }));
    }
    console.log('data', data);
    newOrganization(data);
    // createNewOrganization(data)
    //   .unwrap()
    //   .then((res) => console.log(res))
    //   .catch((e) => console.log(e));
  };

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  if (newSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik
        initialValues={initialValues}
        validationSchema={NewOrganizationSchema}
        onSubmit={onSubmit}
      >
        {({ errors }) => {
          return (
            <ModalContent
              errors={Object.keys(errors).length}
              formInputs={formInputs}
              isLoading={isLoading}
              name='New Organization'
              // extraClass={'h-fit'}
            />
          );
        }}
      </Formik>
    </>
  );
};

export default NewOrganizationModal;
