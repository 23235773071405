import { Search } from './../../components/search/search';
import { useEffect, useState } from 'react';
import DropdownItem from './DropDown/DropDownItem';
import { TableHead } from '../../components/table-head';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { NameAndAvatar } from '../../components/name-and-avatar';
import {
  useDownloadListMutation,
  useGetAllSchoolsQuery,
  useToggleSchoolStatusMutation,
} from '../../slices/schoolsApiSlice';
import TableTd from '../../components/table-td/table-td';
import TableContainer from '../../components/table-container/table-container';
import { SchoolStatus, UserRoles, ProgramTitles, ManualTagPrefix } from '../../utils/constants';
import { Separator } from '../../components/ui/separator/Separator';
import CanIView from '../../components/can-i-view/can-i-view';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { HeaderButton } from '../../components/header-button';
import Pagination from '../../components/pagination';
import { SchoolModel } from '../../model/school/school-model';
import { LoadingSceletonUsers } from '../../routes/tournaments/routes/all/loading-sceleton-users';
import { HiSwitchHorizontal } from 'react-icons/hi';
import { useSearchGameByNameMutation } from '../../slices/gameApiSlice';
import { appendAutoTags } from '../../utils/utils';
import WentWrong from '../../routes/went-wrong/went-wrong';

const Schools = () => {
  const [downloadList, { data, isLoading: downloadLoading }] = useDownloadListMutation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState({ by: 'name', keyword: '' });
  const filterList = [
    { title: 'name', property: 'name' },
    {
      title: 'tags',
      property: 'tags',
    },
    { title: 'city', property: 'city' },
  ];
  const [searchQuery, setSearchQuery] = useState(filterList[0].title);

  const formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = phoneNumberString.replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
  };

  const {
    data: schoolData,
    isLoading,
    error,
  } = useGetAllSchoolsQuery(
    Object.fromEntries(searchParams.entries()),
    // {

    // query: searchQuery,
    // param: searchParams.get(searchQuery) ? searchParams.get(searchQuery) : '',
    // page: searchParams.get('page') ? searchParams.get('page') : 1,
    // }
  );

  useEffect(() => {
    console.log('schoolData = ', schoolData?.data);
  }, [schoolData]);
  const configureListView = [
    {
      name: 'Name',
      active: true,
      prop: 'name',
    },
    {
      name: 'City',
      active: true,
      prop: 'city',
    },
    {
      name: 'State',
      active: true,
      prop: 'state',
    },
    {
      name: 'Phone number',
      active: true,
      prop: 'phoneNumber',
    },
    {
      name: 'Tags',
      active: true,
      prop: 'tags',
    },
    {
      name: 'school status',
      active: true,
      prop: 'status',
    },
  ];
  const handleFilterChange = (query: string, param: string) => {
    setSearchQuery(query);
    setSearchParams({ [query]: param });
    // setSearchParams();
  };

  const [openListView, setOpenListView] = useState(false);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const initialOpenListState = {
    id: null,
    x: null,
    y: null,
  };
  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);
  const [searchGameByName, { data: gameData, isLoading: gameIsLoading, isError: gameIsError }] =
    useSearchGameByNameMutation();
  function handleClick(prop: { id: number; x: number; y: number }) {
    setOpenListView(false);
    if (openList.id == prop.id) {
      setOpenList(initialOpenListState);
    } else {
      setOpenList(prop);
    }
  }

  useEffect(() => {
    searchGameByName(ProgramTitles.DroneRacing);
  }, [searchGameByName]);

  // function handleListView() {
  //   setOpenList(initialOpenListState);
  //   setOpenListView(!openListView);
  // };

  const [listView, setListView] = useState(configureListView);

  const headerButtons = [
    {
      type: 'export',
      view: true,
      func: () => downloadList(Object.fromEntries(searchParams.entries())),
    },
    {
      type: 'configureListView',
      view: true,
      listView,
      setListView,
    },
    {
      type: 'newSchool',
      canIview: 'schools.newSchool',
    },
  ];
  const [
    toggleSchool,
    { data: tData, isLoading: tIsLoading, isError: tIsError, isSuccess: tIsSuccess },
  ] = useToggleSchoolStatusMutation();
  if (isLoading) return <LoadingSceletonUsers />;
  if (error) return <WentWrong />;
  return (
    <div className='listViewContainer fullPage overflow-auto flex flex-col border-1 rounded-xl'>
      <div className='flex w-full justify-between'>
        <div className='flex items-center justify-between'>
          <div className='space-y-1'>
            <h2 className='text-2xl font-semibold tracking-tight'>Schools</h2>
          </div>
        </div>

        <div className='flex justify-end gap-5 content-center items-center '>
          {headerButtons.map(({ canIview, ...others }, i: number) =>
            canIview ? (
              <CanIView key={i} component={canIview}>
                <HeaderButton {...others} />
              </CanIView>
            ) : (
              <HeaderButton {...others} key={i} />
            ),
          )}
        </div>
      </div>
      <div>
        <Separator className='my-4' />
      </div>
      <div className='flex justify-between '>
        {/* mt-8 mb-6 lg:px-8 */}
        <div className='filterContainer'>
          {/* {buttonList.map((b) => (
            <button key={b} className="filterBtn">
              {b}
            </button>
          ))} */}
          <button onClick={() => setSearchParams()} className='filterBtn'>
            Clear Filter
          </button>
          <CanIView component='schools.status'>
            <div className='filterBtn relative '>
              {searchParams.get('status')
                ? [
                    'Status',
                    Number(searchParams.get('status')) === SchoolStatus.Active
                      ? 'Active'
                      : 'Disabled',
                  ].join(' - ')
                : 'Status'}
              <div className='filterDropDown'>
                <span onClick={() => handleFilterChange('status', SchoolStatus.Active.toString())}>
                  Active
                </span>
                <span
                  onClick={() => handleFilterChange('status', SchoolStatus.NotActive.toString())}
                >
                  Disabled
                </span>
              </div>
            </div>
          </CanIView>
        </div>

        <div>
          <Search
            setQuery={setSearchQuery}
            filterList={filterList}
            search={search}
            setSearch={setSearch}
          />
        </div>
      </div>

      <div className='flex flex-col flex-1 justify-between'>
        <TableContainer>
          <TableHead
            tableList={listView
              .map((l) => l.name)
              .filter(
                (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
              )}
            bool={true}
          />
          <tbody className='bg-white divide-y divide-gray-200'>
            {schoolData?.data.map((school: any, i: number) => (
              <tr
                onClick={(e) =>
                  user.roles === UserRoles.Admin
                    ? handleClick({ id: school.id, x: e.pageX, y: e.pageY })
                    : navigate(`/schools/view/${school.id}`)
                }
                className={user.roles !== UserRoles.Admin ? 'cursor-pointer' : 'relative'}
                key={i}
              >
                {listView
                  .filter((l) => l.active)
                  .map((s, i) => {
                    const model: SchoolModel & { [key: string]: any } = new SchoolModel(school);
                    if (s.prop === 'name')
                      return (
                        <TableTd key={i}>
                          <NameAndAvatar image={model.image!} name={model.name!} isAlt={false} />
                        </TableTd>
                      );
                    if (s.prop === 'city')
                      return (
                        <TableTd key={i} extraClass=''>
                          <div className='truncate'>{model.city}</div>
                        </TableTd>
                      );
                    if (s.prop === 'state')
                      return (
                        <TableTd key={i}>
                          <div className='truncate'>{model.state}</div>
                        </TableTd>
                      );
                    if (s.prop === 'phoneNumber')
                      return (
                        <TableTd key={i}>
                          <div className='truncate'>{formatPhoneNumber(model.phoneNumber)}</div>
                        </TableTd>
                      );
                    if (s.prop === 'tags')
                      return (
                        <TableTd key={i}>
                          {typeof model.tags !== 'undefined' && model.tags !== null ? (
                            appendAutoTags(
                              model.tags?.split(',').map((tag: string) => ManualTagPrefix + tag),
                              school.teams,
                              [school],
                              gameData?.[0]?.id,
                            ).map((t: string, i: number) => (
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // Non auto generated tags have a "#" prefix
                                  let isGame;
                                  [t, isGame] = t.startsWith(ManualTagPrefix)
                                    ? [t.slice(1), 'false']
                                    : [t, 'true'];
                                  setSearch((s) =>
                                    s.by === 'tags' && s.keyword === t
                                      ? { by: 'name', keyword: '' }
                                      : { by: 'tags', keyword: t },
                                  );
                                  setSearchQuery('tags');
                                  setSearchParams({
                                    tags: t,
                                    isGame,
                                  });
                                  search.by === 'tags' && search.keyword === t && setSearchParams();
                                }}
                                key={i}
                                className={`tag ${(() => {
                                  const tagNoPrefix = t.startsWith(ManualTagPrefix)
                                    ? t.slice(1)
                                    : t;
                                  return search.by === 'tags' && search.keyword === tagNoPrefix
                                    ? 'active'
                                    : '';
                                })()}`}
                              >
                                {t}
                              </span>
                            ))
                          ) : (
                            <></>
                          )}
                        </TableTd>
                      );
                    if (s.prop === 'status')
                      return (
                        <TableTd key={i}>
                          <span
                            onClick={(e) => {
                              if (user.roles !== UserRoles.Admin) return;
                              e.stopPropagation();
                              toggleSchool(Number(model.id));
                            }}
                            className={`invoiceStatus ${model.status_name?.toLowerCase()} flex items-center gap-1`}
                          >
                            {model.status_name}
                            {user.roles === UserRoles.Admin && <HiSwitchHorizontal />}
                          </span>
                        </TableTd>
                      );
                    return (
                      <TableTd key={i} extraClass='max-w-[15ch] text-sm text-gray-500 truncate'>
                        {model[s.prop]}
                      </TableTd>
                    );
                  })}

                {/* {school.STOP_FROM_RENDERING &&
                    school.organization &&
                    school.organizaiton.STOP_FROM_RENDERING ? (
                    filterByDropdown(school.organization, listView).map((s) => {
                      console.log(s);
                      return s === 'status' ? (
                        <td
                          key={s}
                          className='px-6 py-4 notworking-whitespace-nowrap text-sm text-gray-500'
                        >
                          <span
                            className={`px-2 inline-flex text-xs leading-5
                     font-semibold rounded-full ${school.organization[s]
                                ? 'bg-green-100 text-green-800'
                                : 'bg-red-100 text-red-800'
                              } `}
                          >
                            {school.organization[s] ? 'Active' : 'Disabled'}
                          </span>
                        </td>
                      ) : s === 'tags' ? (
                        <TableTd key={s}>
                          {school.organization[s]?.split(',').map((t: string, i: number) => (
                            <span key={t + i} className='tag'>
                              {t}
                            </span>
                          ))}
                        </TableTd>
                      ) : (
                        <TableTd key={s}>
                          <div className='text-sm text-gray-500'>
                            {school.organization[s] ? 'ok' : 'not ok'}
                          </div>
                        </TableTd>
                      );
                    })
                  ) : (
                    <></>
                  )} */}
                {/* <TableTd>
                    <div className='listViewMenu--parent'>
                      <div onClick={() => handleClick(school.name)}>
                      </div>
                      <div className={openList === school.name ? '' : 'hidden'}>
                        <div
                          className='listViewMenu'
                          role='menu'
                          aria-orientation='vertical'
                          aria-labelledby='menu-button'
                        >
                          <div className='py-1' role='none'>
                            <DropdownItem id={school.id} setFunc={setOpenList}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableTd> */}
                <TableTd>
                  <div className='listViewMenu--parent'>
                    <div
                      className={openList.id === school.id ? '' : 'hidden'}
                      style={
                        openList.id === school.id
                          ? {
                              position: 'fixed',
                              top: Number(openList.y),
                              left:
                                Number(openList.x) < 200
                                  ? Number(openList.x) + 200
                                  : Number(openList.x),
                              zIndex: 99,
                            }
                          : {
                              left: 0,
                            }
                      }
                    >
                      <div
                        className='listViewMenu'
                        role='menu'
                        aria-orientation='vertical'
                        aria-labelledby='menu-button'
                      >
                        <div className='' role='none'>
                          <DropdownItem id={school.id} setFunc={setOpenList} />
                        </div>
                      </div>
                    </div>
                  </div>
                </TableTd>
              </tr>
            ))}
          </tbody>
        </TableContainer>
        <Outlet />

        <Pagination length={schoolData?.data?.length} {...schoolData?.meta} />
      </div>
    </div>
  );
};
export default Schools;
function setModelId(school: any) {
  throw new Error('Function not implemented.');
}
