import { useEffect, useState } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useParams, useSearchParams } from 'react-router-dom';
import { NameAndAvatar } from '../../../../../../../components/name-and-avatar';
import {
  useGetSingleTournamentInfoQuery,
  useTournamentsTeamsQuery,
} from '../../../../../../../slices/tournamentApiSlice';
import { TeamModel } from '../../../../../../../model/team.model';
export function CheckboxListTeamsTournament({
  chosenTeam,
  setChosenTeam,
}: {
  chosenTeam: any;
  setChosenTeam: Function;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const [selectedGame, setSelectedGame] = useState('');
  const { data: tData } = useGetSingleTournamentInfoQuery(params.id);
  const { data, isLoading, isSuccess, isError } = useTournamentsTeamsQuery({
    id: tData.game_id,
    page: searchParams.get('page') ? searchParams.get('page') : 1,
    game: searchParams.get('game') ? searchParams.get('game') : '',
  });
  useEffect(() => {
    setSearchParams({ game: selectedGame });
  }, [selectedGame]);

  function goPrevPage() {
    setSearchParams({ page: `${data.meta.prev}` });
  }
  function goNextPage() {
    setSearchParams({ page: `${data.meta.next}` });
  }

  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='flex flex-col'>
      <div className='flex pt-5 flex-col gap-5'>
        <h2 className='font-bold'>Teams</h2>
        <div className='flex gap-5 items-center'>
          <input
            type='checkbox'
            checked={data.data.every((i: any) => chosenTeam.find((t: any) => t.id === i.id))}
            onChange={() =>
              data.data.every((i: any) => chosenTeam.find((t: any) => t.id === i.id))
                ? setChosenTeam([
                    ...chosenTeam.filter((t: any) => !data.data.find((d: any) => d.id === t.id)),
                  ])
                : setChosenTeam([
                    ...chosenTeam.filter((t: any) => !data.data.find((d: any) => d.id === t.id)),
                    ...data.data,
                  ])
            }
            className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
          />
          Add All Teams
        </div>
        {data.data.map((t: any) => (
          <div key={t.id} className='flex gap-5 items-center'>
            <input
              type='checkbox'
              className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
              onChange={() =>
                chosenTeam.find((i: any) => i.id === t.id)
                  ? setChosenTeam((s: any) => s.filter((a: any) => a.id !== t.id))
                  : setChosenTeam((s: any) => [...s, t])
              }
              checked={Boolean(chosenTeam.find((i: any) => i.id === t.id))}
            />
            <NameAndAvatar image={t.School.image} name={new TeamModel(t).getOptionName()} />
          </div>
        ))}
        <div className='flex w-full justify-between'>
          <span>
            {data.data.length} of {data.meta.total} results
          </span>
          <div className='flex gap-2 items-center'>
            <span>
              {data.meta.currentPage} of {data.meta.lastPage}
            </span>
            <div className='cursor-pointer' onClick={data.meta.prev ? goPrevPage : () => null}>
              <BsArrowLeft color={data.meta.prev ? '#b50303' : 'grey'} />
            </div>
            <div className='cursor-pointer' onClick={data.meta.next ? goNextPage : () => null}>
              <BsArrowRight color={data.meta.next ? '#b50303' : 'grey'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
